@import "variables";
.friend-button-wrapper {
  .default-action-btn {
    color: $gray-9;
    background: $white;
    font-family: $font-stack;
    padding: 6px 15px;
    border-radius: 0px;
    border: 1px solid $gray-3;
    font-weight: 600;

    &:hover {
      background-color: #2980b9;
      color: $white;

      .glyphicon-user {
        color: $white;
      }
    }

    .glyphicon-user {
      margin-right: 8px;
      color: $gray-9;
    }
  }

  .dropdown {
    width: inherit;

    #friend-button {
      background-color: $gray-8;
      color: $white;

      .glyphicon-user {
        margin-right: 8px;
      }
    }

    button { 
      float: right;
      border-radius: 0px;
      font-family: $font-stack;
      font-weight: 600;
      color: $gray-9;
    }

    .dropdown-menu {
      border-radius: 0px;
      font-family: $font-stack;
      margin: 5px 0px;

      li {
      }
    }
  }
}

.mobile-friend-button {
  .fas {
    float: right;
    font-size: 20px;
    padding-right: 20px;
  }

  .fa-user-check {
    color: $gray-2;
  }

  .fa-user-friends {
    color: $blue-primary;
  }

  .fa-user-plus {
    color: $gray-7;

    &:hover {
      cursor: pointer;
    }
  }
}