@import "variables";
.mini-footer {
  height: 60px;
  width: 100%;

  .navbar-brand {
    height: 100%;
    font-family: $font-stack;
    color: $gray-7;
    font-size: 12px;
    padding: 15px 15px;
  }

  .navbar-nav > li > a {
    padding: 15px 10px 10px 10px;
    font-family: $font-stack;
    font-size: 12px;
    color: $gray-7;

    &:focus {
      background-color: $gray-8;
    }

    &:hover {
      background-color: $gray-8;
      color: $gray-5;
    }
  }
} 

.footer-wrapper {
  width: 350px;
  margin-left: auto;
  margin-right: auto;
}

@media (max-width: 767px) {
  .footer-wrapper {
    width: 85%;
    max-width: 400px;

    .navbar-header {
      display: inline-block;
      vertical-align: top;
      .navbar-brand {
        padding: 15px 5px 5px 5px;
        font-size: 12px;  
      }
    }

    .navbar-nav {
      display: inline-block;
      vertical-align: top;
      margin: 0px;

      li {
        display: inline-block;

        a {
          padding: 15px 5px 5px 5px;
          font-size: 12px;
        }
      }
    }
  }
}