@import "variables";
.favorites {
  .setlist-container {
    margin-top: 0px;
    margin-left: 0px;
    margin-right: 0px;
  }

  .other-djs {
    h4 {
      padding: 15px;
      font-family: $font-stack; 
      font-size: 18px;
      font-weight: 600;
      color: $white;
      width: 100%;
      background-color: $blue-primary;
      margin: 0px;
    }
  }

  .load-more {
    margin-right: 10px;
    margin-left: 10px;
    padding: 15px;

    button {
      display: block;
      margin-left: auto;
      margin-right: auto;
      border-radius: 0px;
      font-family: $font-stack;
      font-weight: 600;
    }
  }
}

@media (max-width: 768px) {
  .favorites {
    margin-left: -15px;
    margin-right: -15px;
    
    .heading {
      display: none;
    }
    
    .player {
      margin-left: -15px;
      margin-right: -15px;
      margin-top: 15px;
    }

    .set-list-container {
      margin-left: -15px;
      margin-right: -15px;
    }
  }
}
