form {
	margin-top: 15px;
}

h4 {
	font-family: $font-stack;
	font-size: 16px;
	color: $gray-6;
}

.sending {
	padding: 20px 0px;
	margin-left: auto;
	margin-right: auto;
	text-align: center;

	h3 {
		display: inline-block;
		font-family: $font-stack;
		color: $gray-7;
		margin-right: 20px;
		padding-bottom: 2px;
		font-weight: 600;
		border-bottom: 2px solid $red-primary;
		text-transform: uppercase;
		font-style: italic;
		font-size: 17px;
	}

	.spinner {
		display: inline-block;
		vertical-align: -25px;
	}
}

.invite-status {
	font-family: $font-stack;
	margin-top: 30px;
	padding-left: 20px;
	padding-right: 20px;
	height: 300px;
	overflow: scroll;

	table {
		margin-bottom: 0px;
	}


	thead {
		font-weight: 600;
	}

	tbody {
		font-weight: 400;

		tr {
			background-color: $gray-1;

			td {
				border-color: $gray-7;
				padding: 10px;
			}
		}

		.accept {
			color: $gray-7;
		}

		.invited {
			color: $gray-6;
		}

		tr:first-child {
			td {
				border-top: 0px;
			}
		}
	}

	.status {
		text-transform: uppercase;
		font-weight: 600;
	}
}