@import "variables";
.dj-wall {
  font-family: $font-stack; 
  

  .row {
    margin-left: 0px;
    margin-right: 0px;

    .col-md-* {
      padding: 0px;
    }
  }
}

.dj {
  padding: 0px !important;

  .overlay-wrapper {
    position: relative;
    
    img {
      width: 100%;
      background-color: $gray-3;
    }
    
    .overlay {
      position: absolute;
      text-align: center;
      left: 0px;
      top: 0px;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.6);
      opacity: 0.1;
      
      transition: opacity 0.25s ease;

      h3 {
        font-weight: 400;
        margin-top: 10px !important;
        font-size: 12px;
        text-transform: uppercase;
        color: $white;

        position: absolute;
        bottom: 18px;
        left: 0;
        right: 0;
        margin: 0px;
        padding: 2px;
      }
    }

    &:hover {
      .overlay {
        cursor: pointer;
        opacity: 1.0;
      }
    }
  }
}