.search-dropdown {
  position: absolute;
  width: 100%;
  left: 0px;
  top: 35px;  
  padding: 0px;
  background-color: $white;
  z-index: 1000;
  -webkit-box-shadow: 0 4px 6px 2px rgba(0, 0, 0, .10);
  -moz-box-shadow: 0 4px 6px 2px rgba(0, 0, 0, .10);
  box-shadow: 0 4px 6px 2px rgba(0, 0, 0, .10); 
  border: 1px solid #ccc;
  border-color: rgba(0, 0, 0, .15);

  @media(max-width: 767px) {
    top: 50px; 
  }

  ul {
    list-style-type: none; 
    margin: 0px;
    padding: 0px;

    li {
      padding: 5px 10px;
      font-family: $font-stack;

      &:hover, &.hover {
        cursor: pointer;
        background-color: $gray-2;
      }
    }
  }
}

.search-dropdown-mobile {
  width: 100%;
  padding: 0px;
  background-color: $white;
  border-top: 1px solid #ccc;
  border-color: rgba(0, 0, 0, .15);

  ul {
    list-style-type: none; 
    margin: 0px;
    padding: 0px;

    li {
      padding: 10px 15px;
      font-family: $font-stack;

      &:hover, &.hover {
        cursor: pointer;
        background-color: $gray-2;
      }
    }
  }
}