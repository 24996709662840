.song-info {
  margin-top: 0px;

  .empty-text {
    width: 60px;
    height: 10px;
    background-color: $gray-1;
  }

  .empty-image {
    width: 86px;
    height: 86px;
    background-color: $gray-1;
  }

  section {
    border-bottom: 1px solid $gray-1;
    padding: 15px;
  }

  h4 {
    font-family: $font-stack;
    color: $gray-8;
    font-weight: 700;
    text-transform: uppercase;
    margin: 0px;
    margin-bottom: 10px;
  }

  .genres {
    .genre-label {
      background-color: $red-primary;
      color: $white;
      padding: 3px 6px;

      margin-right: 5px;
      margin-top: 0px;

      font-size: 9px;
      font-family: $font-stack;
      font-weight: 500; 
      text-transform: uppercase;
      line-height: 2;
    }
  }
}

@media (max-width: 767px) {
  .song-info {
    margin-left: -15px;
    margin-right: -15px;
  }
}