.playlist-modal  {
  .btn-group {
    float: right;
    margin-top: 20px;

    .btn {
      border-radius: 0px;
      text-transform: uppercase;
    }
  }

  h3 {
    padding-bottom: 15px;
  }

  .form-control {
    border-radius: 0px;
  }

  @media(max-width: 767px) {
    width: 100%;
  }
}

.playlist-info {
  margin-top: 0px;
  margin-right: -10px;
  
  .empty-text {
    width: 60px;
    height: 10px;
    background-color: $gray-1;
  }

  .empty-image {
    width: 86px;
    height: 86px;
    background-color: $gray-1;
  }

  section {
    border-bottom: 1px solid $gray-1;
    padding: 15px;
  }

  h4 {
    font-family: $font-stack;
    color: $gray-8;
    font-weight: 700;
    text-transform: uppercase;
    margin: 0px;
    margin-bottom: 10px;
  }

  .filter-title { 
    cursor: pointer;
    .glyphicon {
      float: right;
      color: $red-primary;
      font-size: 14px;
    }
  }

  .date-slider {
    padding: 5px 15px;
  }

  .genres {
    .genre-label {
      background-color: $red-primary;
      color: $white;
      padding: 3px 6px;

      margin-right: 5px;
      margin-top: 0px;

      font-size: 9px;
      font-family: $font-stack;
      font-weight: 500; 
      text-transform: uppercase;
      line-height: 2;
    }
  }

  .dj-wall {
    .dj {
      h3 {
        font-size: 12px;
        text-align: center;
        font-weight: 400;
        left: 0px;
        right: 0px;
        padding-left: 5px;
        padding-right: 5px;
      }
    }
  }
}

.editable  {
  .edit-title  {
    float: left;
  }

  p {
    clear: both;
  }

  .glyphicon-pencil {
    float: right;
    color: $gray-3;

    &:hover {
      color: $gray-7;
      cursor: pointer;
    }
  }
}

.editing {
  .centered {
    text-align: center;
  }
  
  p {
    clear: both;
    padding: 6px;
    margin: 0px;
    font-family: $font-stack;
  }

  .btn {
    border-radius: 0px;
    font-family: $font-stack;
    text-transform: uppercase;
    font-size: 13px;
    text-align: center;
  }

  .btn-primary {
    background-color: $blue-primary;
    margin-left: 5px;
  }

  .switch-toggle-container {
    padding: 6px;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: auto;
    margin-right: auto;

    label {
      font-family: $font-stack;
      color: $gray-8;
      font-weight: 500;
      text-transform: uppercase;
      font-size: 14px;
    }

    .switch-button {
      vertical-align: top;
      margin-left: 10px;
      margin-right: 10px;

      &.on {
        background-color: $red-primary;
      }
    }
  }

  input {
    border-radius: 0px;
    width: 100%;
  }

  textarea {
    width: 100%;
    margin-top: 2px;
    padding: 6px 12px;
    resize: vertical;
  }
}

@media (max-width: 767px) {
  .playlist-info {
    margin-left: -15px;
    margin-right: -15px;
  }
}