.m6k-radio {
	display: inline-block;
  cursor: pointer;	

	font-family: $font-stack; 
  font-size: 14px;
  font-weight: 400;
  text-transform: uppercase;
  line-height: 36px;
  padding: 10px 15px;
  margin: -10px -15px;

  width: 100%;

  &:hover {
  	text-decoration: underline;
  }

  &:hover .inner {
    transform: scale(0.75);
    opacity: 0.5;
  }

  &:hover .outer {
  }

  // Native input control and checked events
  input {
    opacity: 0; 
    width: 1px;
    height: 1px;

    &:checked + .outer .inner {
      opacity: 1;
      transform: scale(1);
    }

    &:checked + .outer { 
      border: 1.5px solid $gray-8;
    }
  }

    // Outer ring of custom radio
  .outer {
    display: block;
    float: left;
    margin: 10px 9px 10px 10px;
    border: 1.5px solid $gray-7;
    border-radius: 50%;
    background-color: $white;
  }
  
  // Inner ring of custom radio
  .inner {
    transition: all .15s ease-in-out;

    width: 8px;
    height: 8px;

    transform: scale(0);

    display: block;
    margin: 1.5px;
    border-radius: 50%;
    background-color: $red-primary; 
    opacity: 0;
  }
}