@import "variables";
.confirm-action-modal-overlay {
	position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;

  text-align: center;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);	

  z-index: 9999999;
}

.confirm-action-modal {
	position: absolute;
	top: 300px;
	left: 0px;
	right: 0px;
	max-width: 400px;
  width: 100%;
	margin: auto;

	.modal-header {
		background-color: $white;
		border-bottom: 0px;
		position: relative;

		h5 {
			font-size: 16px;
			margin: 0px;
			font-family: 'Avenir Next';
			font-weight: 400;
			line-height: 20px;
			color: $gray-9;
			float: left;
			text-align: left;
			padding: 15px 25px 15px 15px;
		}

		.cancel-btn {
			position: absolute;
			right: 15px;
			top: 15px;
      font-size: 20px;
      margin: 0px;
      font-weight: 500;
      margin-right: 10px;
      color: $gray-9;
      font-family: 'Gill Sans';

      &:hover {
        cursor: pointer;
      }
    }
	}

	.modal-footer {
		background-color: $white;
		border-top: 0px;
		margin: 0px;
		padding: 10px;

		.btn {
			border-radius: 0px;
			font-family: $font-stack; 
			font-weight: 500;
			letter-spacing: 1.0px;
			text-transform: uppercase;
		}

		.btn-default {
			background-color: $gray-2;
			color: $gray-8;
			margin-right: 10px;
		}

		.btn-primary {
			background-color: $blue-primary;
		}
	}
}