.dj-profile-header {
  display: flex;

  .dj-profile-photo {
    width: 195px;
    padding: 15px;

    img {
      width: 100%;
    }
  } 

  .dj-profile-panel {
    width: 100%;
    position: relative;

    .profile-info-short {
      padding: 15px;

      .follow-button {
        float: right;
      }

      h1 {
        font-family: $font-stack;
        font-size: 24px;
        color: $gray-8;
        font-weight: 700;
        margin-bottom: 0px;
      }

      .country {
        margin-bottom: 10px;
        margin-top: 5px;

        h5 {
          font-family: $font-stack;
          margin-top: 0px;
          font-size: 14px;
          text-transform: uppercase;
          font-weight: 500;
          display: inline-block;
          color: $gray-6;
        }

        img {
          margin-right: 5px;
          height: 15.4px;
          vertical-align: -3px;
          width: inherit;
          display: inline-block;
          opacity: 0.85;
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .dj-profile-header {
    width: auto;
    margin-left: -15px;
    margin-right: -15px;
    margin-top: 0px;


    .dj-profile-panel {
      .profile-info-short {
        padding-left: 10px;
        
        .follow-button {
          float: none;
        }
        h1 {
          font-size: 20px;
          margin-top: 10px;
        }

        .country {
          h5 {
            font-size: 12px;
          }
        }
      }
    }
  }


}