.dropdown-wrapper {
	position: relative;
}

.list-dropdown {
	padding: 0px;
	position: absolute;
	z-index: 1000;

	top: 40px;
	left: 0px;
	right: 0px;

	background-color: $white;
	border: 1px solid $gray-4;

	.list-item {
		list-style-type: none;
		padding: 5px;
		border-bottom: 1px solid $gray-4;

		&:last-child {
			border-bottom: 0px;
		}

		&:hover {
			cursor: pointer;
			background-color: $gray-1;
		}

		.name {
			font-family: $font-stack;
		}

		.glyphicon-plus {
			float: right;
			color: $gray-6;
		}
	}
}