.modal-body.uploading {
	height: 500px;
	background-color: $gray-2;
	position: relative;

	padding: 40px;
}

.uploading-progress-bar {
	position:relative; top:50%; 
	transform:translate(0,50%);

	background-color: $white;	
	width: 100%;
	border-radius: 8px;
	border: 1px solid $gray-5;

	height: 9px;	

	.progressbar-progress {
		border-radius: 8px;
		// border: 1px solid $red-primary;
	}
}
