.collaborators {
  margin-top: 0px;

  .heading {
    background-color: $gray-9;
    color: $white;

    h3 {
      margin: 0px;
      margin-top: 15px;
      padding: 6px 0px;
      text-transform: uppercase;
      font-size: 18px;
      font-weight: 600;
    }

    .add-collab {
      background-color: $white;
      color: $gray-9;
      font-weight: 500;
      border-radius: 0px;
      float: right;
      margin-top: 15px;
      margin-bottom: 15px;

      &:hover {
        color: $red-primary;
        background-color: $gray-1;
      }
    }
  }

  .collaborator-list {
    list-style-type: none;
    padding: 0px; 

    .member {
      display: flex;
      align-items: center;
      justify-content: center;

      padding: 15px;
      border-bottom: 1px solid $gray-1;

      .profile-photo {
        width: 120px;
        height: 120px;
      } 

      .info {
        margin-left: 15px;
        margin-right: auto;

        h3 a {
          color: $gray-9;
        }
      }

      .edit-collab {
        margin-left: auto;

        .btn {
          border-radius: 0px;
          font-size: 17px;
          margin-right: 15px;
        }
      }

      .remove-user {
        margin-left: auto;
        font-size: 18px;
        color: $gray-6;
        vertical-align: middle;

        &:hover  {
          color: $red-primary;
          cursor: pointer;
        }

        .glyphicon-trash {
          display: inline-block;
          margin-right: 10px;
          vertical-align: middle;
        }

        .remove-button {
          display: inline-block;
          border: 0px;
          vertical-align: middle;
        }
      }
    }

    .edit-member { 
      background-color: $gray-1;
      border-bottom: 1px  solid $gray-2;

      .btn-group {
        margin-left: auto;
      }
    }
  }
}

.add-collaborator-modal {
  width: 600px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 120px;

  .modal-header {
    background-color: $gray-9; 
    color: $white;

    h3 {
      margin: 0px;
    }

    .glyphicon-remove {
      font-size: 20px;
      float: right;
      line-height: 26.4px;

      &:hover {
        cursor: pointer;
      }
    }
  }

  .modal-body {
    overflow-y: scroll; 
    height: 500px;

    .filter-friends {
      width: 100%;
    } 

    .friends-list {
      list-style-type: none;
      margin: 0px;
      padding: 0px;

      .friends-list-item {
        border-bottom: 1px solid $gray-3;
        display: flex;
        align-items: center;
        border-left: 5px solid $white;
        margin-left: -15px;
        margin-right: -15px;

        padding: 15px;
        padding-left: 10px;

        &.pending  {
          background-color: $gray-1;
          border-left: 5px solid $gray-5;
        }

        &.added {
          background-color: $gray-2;
          border-left: 5px solid $red-primary;
        }

        img {
          width: 100px;
          height: 100px;
        }

        h5 {
          margin-left: 25px;
          font-size: 22px;
        }

        .btn-group {
          margin-left: auto;
        }
      }
    }
  }
}

.delete-collaborator-modal {
  width: 700px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 120px;

  .modal-header {
    background-color: $gray-9; 
    color: $white;

    h3 {
      margin: 0px;
    }

    .glyphicon-remove {
      font-size: 20px;
      float: right;
      line-height: 26.4px;

      &:hover {
        cursor: pointer;
      }
    }
  }

  .modal-body {}
}