@import "variables";
.home {
  section {
    width: 100%;
    background-color: $white;
    overflow: hidden;
  }

  .hero-section {
    position: relative;

    img {
      width: 100%;
    }

    .hero-overlay {
      position: absolute;
      top: 0px;
      left: 0;
      right: 0;
      
      margin-left: auto;
      margin-right: auto;
      display: block;
      width: 1024px;

      text-align: center;

      h2 {
        color: $white;
        margin-top: 250px;
        font-size: 52px;
        font-weight: 500;
        font-family: $font-stack;
      }

      h3 {
        color: $white;
        font-family: $font-stack;
      }
    }
  }

  .summary-section {
    padding-top: 75px;
    padding-bottom: 75px; 

    h2 {
      margin-bottom: 35px;
    }
  }

  .feature-section {
    padding-top: 75px;
    padding-bottom: 75px; 
  }
}

@media (max-width: 767px) {
  
}