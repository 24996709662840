@import "variables";
.help {
  background-color: $gray-1;
  display: flex;
  flex-direction: column;

  min-height: 100%;

  footer {
    margin-top: auto;
  }

  .sub-nav {
    margin-top: 20px;
    display: table;
  }

  .breadcrumbs {
    overflow: hidden;
    white-space: nowrap;
    display: table-cell;
    padding-left: 0px;

    li {
      color: $gray-7;
      display: inline-block;
      font-size: 16px;
      padding-left: 7px;

      &:first-child {
        padding-left: 0px;
      }

      &:first-child::before {
        content: '';
        margin: 0px;
      }

      &::before {
        content: ">";
        margin: 0 4px;
        padding-right: 7px;
      }

      a {
        color: $gray-7;
        display: inline-block;
        font-size: 16px;
      }
    }
  }

  .m6k-panel {
    background-color: $white;
    box-shadow: 0px 1px 2px 1px rgba(0, 0, 0, 0.095);
    margin-bottom: 35px;
    margin-top: 20px;

    @media (max-width: 767px) {
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }

  .categories {
    padding: 25px;
    flex: 1;

    display: flex;
    justify-content: center;
    flex-direction: column;

    .row {
      display: flex;
      flex-wrap: wrap;
    }

    .row > [class*='col-'] {
      display: flex;
      flex-direction: column;
    }


    .category {
      margin-bottom: 25px;

      h2 {
        font-weight: 700;
        margin-bottom: 15px;
        margin-top: 0px;
        color: $gray-9;

        a {
          color: $gray-9;
        }
      }

      ul {
        margin-left: 0px;
        padding-left: 0px;
      }
      ul li {
        list-style-type: none;
        font-size: 18px;

        a {
          color: $gray-7;
        }
      }
    }
  }

  .navbar {
    border-radius: 0px;
    margin-bottom: 0px;
    background-color: $white;
    height: 58px;

    h1 {
      color: $gray-9;
      font-family: $logo;
      font-weight: 700;
      // font-size: 36px  ;
      line-height: 55px;
      display: inline-block;
      margin: 0px;
      vertical-align: middle;
    }

    h3 {
      display: inline-block;
      margin-left: 25px;
      vertical-align: middle;
    }

    .navbar-nav {
      .sign-in {
        font-size: 20px;
        line-height: 55px;
        padding-top: 0px;
        padding-bottom: 0px;
        margin-right: 15px;
      }
    }
  }  
}