@import "variables";
.ReactModal__Overlay {
  z-index: 1000;
}

.playlist-modal {
  width: 600px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 120px;

  .modal-header {
    background-color: $gray-9; 
    color: $white;
    padding-bottom: 0px;

    .modal-nav {
      list-style-type: none;
      padding-left: 0px;
      margin-bottom: 0px;

      li {
        display: inline-block;
        font-size: 20px;
        border-bottom: 3px solid $gray-9;
        margin-bottom: 0px;
        padding-bottom: 12px;
        margin-right: 15px;

        &:hover {
          cursor: pointer;
        }

        &.active {
          border-bottom-color: $red-primary;
        }
      }
    }

    h3 {
      margin: 0px;
    }

    .glyphicon-remove {
      font-size: 20px;
      float: right;
      line-height: 26.4px;

      &:hover {
        cursor: pointer;
      }
    }
  }

  .modal-body {
    .song-summary {
      padding: 10px;
      background-color: $gray-1;
      margin-bottom: 15px;
      margin-left: -15px;
      margin-right: -15px;
      margin-top: -15px;

      img {
        width: 60px;
        margin-right: 15px;
      }
    }

    .add-to-playlist-form {

      .playlists {
        list-style-type: none;
        padding-left: 0px;
        max-height: 400px;
        overflow-y: scroll;

        .playlist-summary {
          margin-bottom: 15px;

          img {
            width: 100%;
            display: inline-block;
            margin-right: 15px;
          }

          h4 {
            display: inline-block;
          }

          button {
            border-radius: 0px;
            text-transform: uppercase;
          }

          .added {
            border: 1px solid $gray-3;
            text-transform: uppercase;
            padding: 6px 12px;

            background-color: $blue-primary;
            color: $white;
          }

          .glyphicon-lock {
            color: $gray-7;
            font-size: 13px;
            margin-right: 5px;
          }

          .add-button {
            width: 180px;
          }
        }
      }
    }

    form {
      label {
        text-transform: uppercase;
      }

      p {
        color: $gray-6;
        margin-bottom: 15px;
      }

      textarea { resize: vertical; }

      input, textarea {
        border-radius: 0px;
      }

      .btn-group {
        float: right;

        .btn-primary {
          background-color: $blue-primary;

          &:hover {
            background-color: $blue-primary-darker;
          }
        }
      }

      .switch-toggle-container {
        padding: 0px;
        margin-bottom: 10px;
        margin-left: auto;
        margin-right: auto;

        .switch-button {
          vertical-align: top;
          margin: 0px 10px;
        }

        .switch-button.on {
          background-color: $red-primary;
        }

        label {
          font-weight: 500;
        }
      }
    }
  }
}

@media(max-width: 767px) {
  .ReactModal__Overlay {
    // background-color: rgba(255, 255, 255, 1.0) !important;
    overflow: scroll;
  }

  .ReactModal__Body--open {
    overflow: hidden;
    position: fixed;
  }

  .playlist-modal-html {
    position: fixed; 
    overflow: hidden;
  }

  .playlist-modal {
    margin-top: 0px;
    width: 100%;

    .modal-header {
      .modal-nav {
        li {
          font-size: 16px;
        }
      }
    }

    .modal-body {
      .add-to-playlist-form {
        .playlists .playlist-summary .add-button {
          width: 100px;
        }
      }
    }
  }
}