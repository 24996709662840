@import "variables";
@import 'feed-template-modal';

.song-feed {

  .template-button {
    float: right;
    margin-top: 15px;
    background-color: $gray-9;
    color: $white;
    border-radius: 0px;
  }

  .bpm-submit {
    font-family: $font-stack;
    background-color: $gray-6;
    color: $white;

    &:active, &:focus {
      outline: transparent;
    }
  }

  .bpm-filters {
    padding: 15px;
    display: flex;

    .input-group  {
      input {
        border-radius: 0px;
      }   
    }
  }

  .setlist-empty {
    width: 100%;
    padding: 20px;
    text-align: center;
    list-style-type: none;
    border-top: 1px solid $gray-2;

    .loading {
      margin-right: auto;
      margin-left: auto;
      width: 45px !important;
    }

    .logo {
      font-family: $logo;
      color: $gray-8;
      font-weight: 800;
      text-align: center;
      font-size: 28px;
      margin: 0px;
      padding: 0px;
    }
  }
}