@import "variables";
@import 'header';
@import 'modal';

@import 'm6k_form';
@import 'm6k_panel';
@import 'm6k_popover';
@import 'm6k_slider';
@import 'm6k_tabs';

// TODO: refactor search into component with style
@import 'search_bar';

// TODO: refactor into component with styling
@import 'm6k_checkbox';


.app {
  display: flex;
  min-height: calc(100vh - 90px);
  flex-direction: column;
  background-color: rgb(230, 228, 226);
}

.app-container {
  flex: 1;
}

.container-fluid {
  padding: 0px;
  max-width: 1270px;
  min-width: 785px;

  .row {
    margin-left: 0px;
    margin-right: 0px; 
  }
}

.m6k-float-left {
  float: left;
}

.m6k-float-right {
  float: right;
}

.m6k-fixed-col-3 {
  min-width: 317.5px!important;
}

.m6k-btn {
  border-radius: 0px;
  text-transform: uppercase;
}
