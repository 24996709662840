@import "variables";
.login-page, .reset-password-page {
  background-color: $gray-8;
  height: 100%;
  padding-top: 60px;

  .login-panel, .reset-password-panel {
    margin-left: auto;
    margin-right: auto;
    background-color: white;
    padding: 30px;
    width: 350px;
    box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.2);
  }
  
  .login, .reset {
    width: 100%;
    background-color: $gray-9;
    font-family: $font-stack;
    font-weight: 600;
    font-size: 16px;
    letter-spacing: 1.0px;
    color: white;
    text-transform: uppercase;
    border: none;
    border-radius: 0px;
    margin-top: 15px;
  }

  .loading {
    display: block;
    margin-left: auto;
    margin-right: auto;

    margin-top: 20px;
    width: 65px;
  }

  .reset {
    font-size: 14px;
    padding: 12px;
  }

  .error-message {
    font-family: $font-stack;
    font-weight: 400;
    font-size: 14px;
    padding: 4px;
    padding-left: 12px;
    background-color: $red-primary;
    color: $white;
    border: 1px solid $red-primary;
    display: inline-block;
    vertical-align: 5px;
    width: 87%;
    position: relative;
    
    &:after {
      content: '';
      display: block;
      position: absolute;
      background-color: $red-primary;
      top: 7px;
      left: -7px;
      width: 11px;
      height: 11px; 
      border-right: 1px solid $red-primary;
      border-bottom: 1px solid $red-primary;
      -moz-transform:rotate(135deg);
      -webkit-transform:rotate(135deg);
    }
  }

  .glyphicon-exclamation-sign {
    font-size: 20px;
    color: $red-primary;
    width: 13%;
  }

  .waitlist {
    text-align: center;

    h6 {
      font-family: $font-stack;
      color: $gray-5;
      margin-top: 13px;
      margin-bottom: 0px;
      text-transform: uppercase;
    }
  }

  .password-reset {
    text-align: left;

    h6 {
      font-family: $font-stack;
      color: $gray-7;
      font-style: italic;
    }
  }

  input {
    border-radius: 0px;
    font-family: $font-stack;
    border-top: none;
    border-left: none;
    border-right: none;
    box-shadow: none;
    border-bottom: 2px solid $gray-6;
    letter-spacing: 0.25px;
    font-weight: 500;
    color: $gray-7;

    &:focus {
      outline: 0 none;
      box-shadow: none;
    }

    &::placeholder {
      text-transform: uppercase;
      font-weight: 500;
    }

    &.form-error {
      border-bottom-color: $red-primary;
    }
  }

  h1 {
    margin-top: 0px;
    text-transform: uppercase;
    font-family: $logo;
    font-weight: 700;
    font-size: 28px;
    color: $gray-8;
    text-align: center;
  } 

  h3 {
    font-family: $font-stack;
    font-weight: 600;
    text-align: center;
    font-size: 18px;
    margin-top: 0px;
    color: $gray-6;
  }

  p {
    font-family: $font-stack;
    font-weight: 500;
    text-align: left;
    font-size: 16px;
    color: $gray-6;

    margin-top: 20px;
    margin-bottom: 20px;
  }

  .navFooter {
    font-family: $font-stack;
    color: $gray-4;
    margin-right: 10px;
    padding-top: 20px;
    display: block;
  }
}

@media (max-width: 767px) {
  .login-page, .password-reset-page {
    padding-top: 30px;

    .login-panel, .password-reset-panel, {
      width: 85%;
      max-width: 400px;
    }
  }
}