.search-bar {
  width: 550px;
  margin-left: auto;
  margin-right: auto;

  form {
    font-family: $font-stack;
    font-weight: 500;
    font-size: 20px;
    position: relative;

    button {
      border-radius: 0px;
      padding-top: 12px;
      padding-bottom: 12px;
      padding-left: 18px;
      padding-right: 18px;
      display: inline-block;
      vertical-align: top;
    }

    .suggestion-input {
      display: inline-block;
      width: 498px;
    }

    input {
      border-radius: 0px;
      border-right: none;
      padding-top: 22px;
      padding-bottom: 22px;

      width: 100% !important;
    }
  }
}

.suggestions {
  font-family: $font-stack;
  font-weight: 500;
  font-size: 14px;
  position: absolute;
  z-index: 11;
  width: 100%;

  background-color: white;
  border-left: 1px solid rgb(204, 204, 204);
  border-right: 1px solid rgb(204, 204, 204);
  border-bottom: 1px solid rgb(204, 204, 204);

  h5 {
    margin: 0px;
    color: $gray-7;
    font-size: 12px;
    padding: 8px 8px 4px; 
    font-style: italic;
    text-align: right;
  }

  .suggestion {
    padding: 8px 12px;
    width: 100%;
    color: $gray-7;

    .bold {
      font-weight: 700;
      color: $gray-8;
    }

    &:focus {
      background-color: rgb(230, 230, 230);
      outline-width: 0;
    }

    &:hover {
      background-color: rgb(230, 230, 230);
      cursor: pointer;
    }
  }
}

@media (max-width: 767px) {
  .search-bar {
    width: 100%;

    button {
      width: 15% !important;
    }

    .suggestion-input {
      width: 85% !important;
    }

    input {
      width: 100% !important;
    }
  }
}
