.song-nav {
  background-color: $white;
  border-top: 1px solid $gray-1;
  width: 100%;
  margin-bottom: 0px;
  padding-left: 15px;

  position: absolute;
  bottom: 0;

  .profile-nav-item {
    list-style-type: none;
    display: inline-block;
    margin-right: 15px;

    a {
      padding: 10px;
      display: block;
      border-bottom: 2px solid $white;

      h4 {
        font-family: $font-stack;
        text-transform: uppercase;
        letter-spacing: 1px;
        font-weight: 600;
        font-size: 13px;
        display: inline-block;
        color: $gray-8;
      }

      h5 {
        font-family: $font-stack;
        text-transform: uppercase;
        letter-spacing: 0.5px;
        font-weight: 600;
        font-size: 14px;
        display: inline-block;
        margin-left: 10px;
        color: $gray-5;
      }

      &:hover {
        cursor: pointer;
        border-bottom: 2px solid $red-primary;
        transition:border-bottom-color 0.4s ease;
      }

      &.active {
        border-bottom: 2px solid $red-primary;
      }
    }
  }
}

@media (max-width: 767px) {
  .song-nav {
    margin-left: -15px;
    margin-right: -15px;
    padding-left: 0px;
    position: relative;
    width: auto;
    border-bottom: 1px solid $gray-1;

    .profile-nav-item {
      a {
        padding: 8px;

        h4 {
          font-size: 12px;
          letter-spacing: 0px;
        }

        h5 {
          display: none;
        }
      }
    }
  }
}

@media (max-width: 480px) {
  .song-nav {
    .profile-nav-item {
      a {
        padding: 6px;

        h4 {
          font-size: 10px;
        }
      }
    }
  }
}