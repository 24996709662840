.dj-playing {
  width: 100%;
  padding: 0px 17.5px;

  h5 {
    text-transform: uppercase;
    color: $white;
    font-size: 11px;
    letter-spacing: 1px;
  }

  .dj-wall {
    width: 100%;

    .overlay-wrapper img {
      width: 111px;
      height: 111px;
    }

    .overlay {
      h3 {
        display: block !important;
        text-align: center !important;
        font-size: 12px !important;
        font-weight: 400 !important;
        left: 0px !important;
        right: 0px !important;
        padding-left: 2px !important;
        padding-right: 2px !important;
        bottom: 5px;
      }
    }

    .dj {
      width: 111px !important;
      height: 111px !important;
    }
  }

  img {
    width: 90px;
    height: 90px;
  }

  .dj-popover {
  	position: absolute;
  	width: 400px;
  	bottom: 100px;
  	right: 0;

  	text-align: left;

	  box-shadow: 0px 1px 2px 1px rgba(0, 0, 0, 0.155);
  	margin-left: auto;
  	margin-right: auto;

  	z-index: 200;

  	background-color: #222;

	  h3 {
	    font-size: 16px;
	    color: $white;
	    text-align: left;
	    padding: 15px;
	    margin: 0;
	    font-family: $font-stack;
	    font-weight: 500;
	    display: inline-block;
	  }

	  .country {
	  	width: 30px;
	  	height: 15px;
	  	display: inline-block;
	  	margin-left: 15px;
	  }

	  .other-djs {
	  	background-color: $white;

	  	h5 {
	  		font-family: $font-stack;
	  		font-size: 14px;
	  		color: $gray-7;
	  		text-align: left;
	  		padding: 10px;
	  		margin: 0px;
	  	}
  }
  }
}