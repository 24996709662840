@import "variables";
.compact-playlist {
  margin-top: 15px;
  padding: 15px;
  padding-right: 0px;
  display: flex;
  width: 100%;

  .playlist-art {
    height: 200px;
  }

  .list-area {
    width: 100%;
  }

  .compact-list {
    margin-bottom: 0px;
    max-height: 200px;
    overflow-x: hidden;
    overflow-y: scroll;
    padding-left: 10px;
    padding-right: 15px;

    width: 100%;

    .compact-playlist-item {
      border-bottom: 1px solid $gray-2;
      list-style-type: none;
      display: flex;
      width: 100%;

      position: relative;

      left: 0px;
      top: 0px;
      
      border-left: 3px solid #fff;

      &.active {
        border-left: 3px solid $red-primary;
        background-color: $gray-1;
      }

      padding-bottom: 5px;
      padding-top: 5px;

      .m6k-thumbnail {
        position: relative;
        display: inline-block;
        width: 70px;
        margin-right: 15px;
      }

      .song-details {
        .song-description {
          margin-right: 90px;
        }

        .player-control {
          align-self: center;
          width: auto;
          height: auto;
          margin-right: 10px;
          padding-bottom: 5px;

          .icon-display {
            &:hover {
              cursor: pointer;
            }

            .glyphicon {
              font-size: 18px;
              color: $gray-8;
              border: none; 
              background-color: initial;
              box-shadow: none;
              padding: 0px;
            }
          }
        }

        .playlist-dropdown {
          padding: 5px;
          padding-top: 0px;
        }
      } 
    }
  }
}