.song-header {
  display: flex;
  width: 100%;

  .song-album-art {
    width: 390px;
    padding: 15px;

    .empty-image {
      width: 272px;
      height: 153px; 
      background-color: $gray-1;
    }

    img {
      width: 100%;
    }

    .song-nav {
    }
  }

  .song-detail-panel {
    width: 100%;
    position: relative;

    .title {
      width: 100%;
      padding: 5px;
    }

    .empty-title {
      width: 500px;
      height: 14px;
      background-color: $gray-1;
      margin-top: 20px;
    }

    .song-actions {
      padding-left: 15px;
      padding-top: 15px;

      button {
        border-radius: 0px;
        padding-top: 4px;
        padding-bottom: 4px;
        text-transform: uppercase;
        font-size: 12px;

        &:hover {
          cursor: pointer;
          color: $red-primary;
        }

        .glyphicon {
          margin-right: 6px;
          font-size: 14px;
        }
      }
    }

    h1 { 
      font-family: $font-stack;
      font-weight: 600;
      font-size: 22px;
      color: $gray-8;
      display: inline-block;
      margin-left: 15px;
      vertical-align: middle;
      width: 85%;
      overflow: hidden;
      margin: 0px;
      margin-left: 10px;
    }

    .duration {
      display: inline-block;
      font-family: $font-stack;
      font-weight: 700;
      font-size: 20px;
    }
  }

   .icon-display {
      display: inline-block;
      vertical-align: middle;

      .glyphicon {
        font-size: 18px;
        color: $gray-7;
        padding: 14px;
      }

      .glyphicon-pause {
        font-size: 18px;
        color: $gray-7;
        border-radius: 50%;
        padding: 14px;
        border: 1px solid $gray-8;

        &:hover {
          cursor: pointer;
        }
      }

      .glyphicon-play { 
        font-size: 18px;
        color: $gray-7;
        border-radius: 50%;
        padding: 14px;
        border: 1.4px solid $gray-7;
        background-color: $gray-2;

        &:hover {
          cursor: pointer;
          color: $red-primary;
          border-color: $gray-8;
        }
      }
    }
}

@media (max-width: 767px) {
  .song-header {
    margin-left: -15px;
    margin-right: -15px;
    margin-top: 0px;
    width: auto;
    display: block;

    .song-album-art{
      width: 100%;

      .empty-image {
        width: 100%;
      }
    }

    .song-detail-panel {
      padding-left: 15px;

      .title {
        padding: 0px;
      }

      .song-actions {
        padding: 15px;
        text-align: center;
      }

      h1 { 
        font-family: $font-stack;
        font-weight: 500;
        font-size: 16px;
        display: inline-block;
        vertical-align: middle;
        width: 75%;
        overflow: hidden;
        margin: 0px;
        margin-left: 8px;
      }

      .duration {
        font-size: 16px;
        margin: 0px;
      }
    }

   .icon-display {
      .glyphicon {
        font-size: 14px;
        padding: 10px;
      }
   }
  }
}

@media (max-width: 480px) {
  .song-header {
    .song-detail-panel {
      h1 {
        width: 70%;
      }
    }
  }
}