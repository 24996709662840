@import "variables";
.filters {
  h3 {
    font-family: $font-stack;
    text-transform: uppercase;
    font-size: 18px;
    padding: 15px 20px;
    border-bottom: 1px solid $gray-1;
    margin: 0px;
  }

  .date-slider {
    padding: 15px 30px;
  }

  .friends-degree-filter {
    padding: 0px 20px;
  }

  .city-filters-results {
    padding: 5px 0px;

    .city-filter-toggles {
      padding: 10px 0px;
    }
  }

  .city-filters-select {
    padding: 15px;

    .dropdown-wrapper {
      position: relative;
    }

    .list-dropdown {
      box-shadow: 0 4px 7px rgba(0, 0, 0, 0.175);

      padding: 0px;
      position: absolute;
      z-index: 1000;

      top: 45px;
      left: 0px;
      right: 0px;

      background-color: $white;
      border: 1px solid $gray-4;

      .list-item {
        list-style-type: none;
        padding: 5px;
        border-bottom: 1px solid $gray-4;

        &:last-child {
          border-bottom: 0px;
        }

        &:hover {
          cursor: pointer;
          background-color: $gray-1;
        }

        .name {
          font-family: $font-stack;
        }

        .glyphicon-plus {
          float: right;
          color: $gray-6;
        }
      }
    }

    .city-filter-input {
      width: 100%;
      padding: 8px;
      font-family: $font-stack;
      font-weight: 500;
      border: 1px solid $gray-3;

      &:focus {
        box-shadow: none; 
        outline: 2px solid $blue-primary;
      }
    }
  }

  .filter-section {
    h4 {
      font-family: $font-stack;
      text-transform: uppercase;
      color: $gray-8;
      font-weight: 600;
      font-size: 14px;
      letter-spacing: 1px;
      border-bottom: 1px solid $gray-2;
      padding: 10px 20px;

      &:hover {
        cursor: pointer;
      }

      .glyphicon {
        float: right;
        color: $red-primary;
      }
    }
  }
}

.active-filters {
  margin-top: 8px;
  
  h3 {
    font-family: $font-stack;
    font-size: 15px;
    display: inline-block;
    margin-bottom: 0px;
    margin-right: 5px;
  }

  label {
    display: inline-block;
    margin-bottom: 0px;
    margin-left: 5px;
    margin-right: 5px;
    margin-top: 10px;
    padding: 8px 12px;

    font-weight: 400;
    font-size: 15px;
    color: $gray-6;
    background-color: $white;

    &:hover {
      color: $gray-8;
      cursor: pointer;
    }

    span {
      margin-left: 7px;
    }
  }
}
