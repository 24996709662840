@import "variables";
.article {
  .m6k-panel {
    padding: 20px;
    margin-top: 15px;
  }

  .article-name h1 {
    margin-top: 0px;
    margin-bottom: 25px;
    font-size: 32px;
    font-family: $font-stack;
    font-weight: 700;
  }

  .article-body {
    p {
      font-size: 18px;
      line-height: 32px;
    }
  }
}