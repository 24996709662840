@import "variables";
.invitation {
  background-color: $gray-8;
  height: 100%;
  padding-top: 60px;

  .invitation-panel {
    margin-left: auto;
    margin-right: auto;
    background-color: white;
    width: 350px;
    box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.2);

    .checkbox-wrapper {
      display: inline-block;
    }

    .agreement-checkbox {
      position: relative;
      margin-top: 15px;

      .m6k-checkbox {
        display: inline-block;
        vertical-align: middle;

        input+.option-label:before {
          width: 14px;
          height: 14px;
          top: 5px;
        }
      }

      .message {
        display: inline;
        font-family: $font-stack;
        font-size: 11px;
        color: $gray-7;
        font-weight: 400;

        a {
          color: $blue-primary;
          display: inline-block;
        }
      }
    }

    .info {
      font-family: $font-stack;
      font-size: 12px;
      color: $gray-7;
      margin-top: 7px;
    }

    form {
      padding: 20px 30px;
    }
    
    .sign-up {
      width: 100%;
      background-color: $gray-9;
      font-family: $font-stack;
      font-weight: 600;
      font-size: 16px;
      letter-spacing: 1.0px;
      color: white;
      text-transform: uppercase;
      border: none;
      border-radius: 0px;
      margin-top: 15px;
    }

    h2 {
      font-family: $logo;
      font-weight: 700;
      font-size: 28px;
      text-transform: uppercase;
      text-align: center;
      margin-top: 0px;
      padding-top: 20px;
      margin-bottom: 5px;
      color: $gray-8;
    }

    h5 {
      font-family: $font-stack;
      font-size: 16px;
      text-align: center;
      padding: 15px;
      color: $white;
      text-transform: uppercase;
      font-weight: 700;
      background-color: $blue-primary;

      &.important {
        background-color: $red-primary;
      }
    }

    h3 {
      font-family: $font-stack;
      font-size: 18px;
      text-align: center;
      font-weight: 500;
      margin-top: 15px;
      padding: 0px 20px;
      color: $gray-6;
      line-height: 25px;
    }

    input {
      border-radius: 0px;
      font-family: $font-stack;
      border-top: none;
      border-left: none;
      border-right: none;
      box-shadow: none;
      border-bottom: 2px solid $gray-6;
      letter-spacing: 0.25px;
      font-weight: 500;
      color: $gray-7;

      &:disabled {
        background-color: $white;
      }

      &:focus {
        outline: 0 none;
        box-shadow: none;
        border-bottom: 2px solid #6cacdf;
      }

      &::placeholder {
        text-transform: uppercase;
        font-weight: 500;
      }

      &.form-error {
        border-bottom-color: $red-primary;
      }
    }
  }

  .error-message {
    font-family: $font-stack;
    font-weight: 400;
    font-size: 14px;
    padding: 4px;
    padding-left: 12px;
    background-color: $red-primary;
    color: $white;
    border: 1px solid $red-primary;
    display: inline-block;
    vertical-align: 5px;
    width: 87%;
    position: relative;
    
    &:after {
      content: '';
      display: block;
      position: absolute;
      background-color: $red-primary;
      top: 7px;
      left: -7px;
      width: 11px;
      height: 11px; 
      border-right: 1px solid $red-primary;
      border-bottom: 1px solid $red-primary;
      -moz-transform:rotate(135deg);
      -webkit-transform:rotate(135deg);
    }
  }

  .glyphicon-exclamation-sign {
    font-size: 20px;
    color: $red-primary;
    width: 13%;
  }
  
  .loading {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    padding-bottom: 20px;
    width: 65px;
  }
}