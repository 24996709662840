$red-primary: #E74C3C;
$blue-primary: #0070BA;
$blue-primary-darker: darken( $blue-primary, 10% );
$green-primary: #A3D126;
$yellow-primary: #FFC610;

$white: #fff;
$gray-1: #eee;
$gray-2: #ddd;
$gray-3: #ccc;
$gray-4: #bbb;
$gray-5: #aaa;
$gray-6: #999;
$gray-7: #666;
$gray-8: #333;
$gray-9: #222;

$black: #000;
