@import "variables";
.unsubscribe-page {
  background-color: $gray-8;
  height: 100%;
  padding-top: 60px;

  .footer-wrapper {
    width: 400px;
  }

	.unsubscribe-panel {
    margin-left: auto;
    margin-right: auto;
    background-color: white;
    width: 400px;
    box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.2);
    padding: 15px;
	}

  .loading {
    display: block;
    margin-left: auto;
    margin-right: auto;

    margin-top: 20px;
    width: 65px;
    margin-bottom: 15px;
  }

	 h1 {
    margin-top: 0px;
    margin-bottom: 15px;
    text-transform: uppercase;
    font-family: $logo;
    font-weight: 700;
    font-size: 36px;
    color: $gray-8;
    text-align: center;
  }  

  .info {
    font-family: $font-stack;
    color: $gray-5;
    font-weight: 500;
    font-size: 13px;
    text-align: center;

    a {
      text-decoration: underline;
      color: $gray-7;
    }
  }

  h3 {
    font-family: $font-stack;
    font-size: 20px;
    color: $gray-8;
    font-weight: 500;
    text-align: center;
    margin-top: 0px;
  }

  .success-notice {
    h3 {
      font-size: 17px;
      font-weight: 500;
    }

    ul {
      list-style: none;
      margin-left: 0px;
      padding-left: 0px;
      margin-top: 30px;
      margin-bottom: 30px;
    }

    ul > li:before {
      display: inline-block;
      content: "-";
      font-weight: 700;
      width: 1em;
      margin-left: 0px;
    }

    li {
      font-family: $font-stack;
      font-weight: 600;
      font-size: 15px;
      background-color: $gray-1;
      padding: 7px 15px 7px 30px;
      margin-left: -15px;
      margin-right: -15px;
    }
  }

  .unsubscribe {
    width: 100%;
    background-color: $blue-primary;
    font-family: $font-stack;
    font-weight: 600;
    font-size: 16px;
    letter-spacing: 1.0px;
    color: white;
    text-transform: uppercase;
    border: none;
    border-radius: 0px;
    margin-top: 15px;
  }

  .subscriptions {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-align-content: center;
    align-content: center;
  }

  .subscription {
    position: relative;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 15px;
    margin-bottom: 15px;

    .subscription-label {
      font-size: 16px;
      font-family: $font-stack;
      font-weight: 500;
      color: $gray-7;
      display: block;
    }

    input {
      position: absolute;
      opacity: 0;
    }

    input+.subscription-label:before {
      content: "";
      border: 1px solid;
      box-sizing: border-box;
      content: "";
      display: inline-block;
      left: 5px;
      margin: 0 10px 0 0;
      outline: 2px solid;
      overflow: hidden;
      position: absolute;
      top: 3px;
      height: 15px;
      width: 15px;
    }

    input:not(checked)+.subscription-label:before {
      outline-color: transparent;
      background-color: transparent;
    }

    input:checked+.subscription-label:before {
      background-color: $gray-8;
      border-color: $white;
      outline-color: $gray-8;
    }

    input+.subscription-label {
      color: $gray-8;
      text-decoration: none;
      line-height: 1.4;
      margin-left: 30px;
    }
  }

}
