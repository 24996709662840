@import "variables";
footer {
  background-color: $white;
  width: 100%;
  height: 80px;
  border-top: 1px solid $gray-2;

  .container-fluid {
    position: relative;
    height: 100%;
  }

  .navbar-brand {
    height: 100%;
    font-family: $font-stack;
    color: $gray-7;
    font-size: 13px;
    padding: 25px 15px;
  }

  .navbar-nav > li > a {
    padding: 25px 20px 20px 20px;
    font-family: $font-stack;
    font-size: 13px;
    color: $gray-8;

    &:focus {
      background-color: $white;
    }

    &:hover {
      background-color: $white;
      color: $gray-5;
    }
  }
}

@media (max-width: 767px) {
  footer {
    height: 70px;

    .container-fluid {
      .navbar-header {
        margin-left: 0px;
        margin-right: 0px;
        display: inline-block;
        vertical-align: top;
      }

      .navbar-nav {
        margin: 0px;
        display: inline-block;
        vertical-align: top;

        li {
          display: inline-block;

          a {
            padding: 25px 10px 20px 10px;
            font-size: 11px;
          }
        }
      }
    }

    .navbar-brand {
      font-size: 11px;
      display: inline-block;
    }
  }
}