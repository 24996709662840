.dj-info {
  margin-top: 0px;
  margin-right: -10px;
  
  .empty-text {
    width: 60px;
    height: 10px;
    background-color: $gray-1;
  }

  .empty-image {
    width: 86px;
    height: 86px;
    background-color: $gray-1;
  }

  section {
    border-bottom: 1px solid $gray-1;
    padding: 15px;
  }

  h4 {
    font-family: $font-stack;
    color: $gray-8;
    font-weight: 700;
    text-transform: uppercase;
    margin: 0px;
    margin-bottom: 10px;
  }

  .filter-title { 
    cursor: pointer;
    .glyphicon {
      float: right;
      color: $red-primary;
      font-size: 14px;
    }
  }

  .date-slider {
    padding: 5px 15px;
  }

  .genres {
    .genre-label {
      background-color: $red-primary;
      color: $white;
      padding: 3px 6px;

      margin-right: 5px;
      margin-top: 0px;

      font-size: 9px;
      font-family: $font-stack;
      font-weight: 500; 
      text-transform: uppercase;
      line-height: 2;
    }
  }

  .dj-wall {
    .dj {
      h3 {
        font-size: 12px;
        text-align: center;
        font-weight: 400;
        left: 0px;
        right: 0px;
        padding-left: 5px;
        padding-right: 5px;
      }
    }
  }
}

@media (max-width: 767px) {
  .dj-info {
    margin-left: -15px;
    margin-right: -15px;
  }
}