@import "variables";
@import 'song-nav';
@import 'song-header';
@import 'song-info';

.all-djs {
  text-align: center;

  h3 {
    font-family: $font-stack;
    padding: 10px;
    text-transform: uppercase;
    margin-bottom: 0px;
    font-size: 20px;
    font-weight: 600;
  }
}
