@import "variables";
@import 'confirm_password';
@import 'email_notifications';
@import 'blocked_users';

.settings {
  @import 'invitations';

  h1 {
    font-family: $font-stack;
    font-weight: 600;
    font-size: 30px;
  }

  .settings-panel {
    width: 800px;
    margin-left: auto;
    margin-right: auto;
  }

  .vertical-nav {
    padding: 0px;
    height: 550px;
    border-right: 1px solid $gray-3;
    font-family: $font-stack;
    font-weight: 500;

    ul {
      list-style: none;
      padding: 0px;

      li {
        padding: 0px;
        text-align: left;
        border-bottom: 1px solid $gray-2;
        background-color: $white;
        position: relative;

        a {
          width: 100%;
          display: block;
          padding: 15px 20px;
          text-decoration: none;
          color: $gray-6;
          border-left: 3px solid $white;


          &:hover {
            color: $gray-8;
          }
        }

        &.active {
          a {
            color: $gray-8;
            font-weight: 700;
            background-color: $gray-1;
            border-left: 3px solid $red-primary;
          }
        }
      }
    }
  }

  .content {
    padding: 0px;
    height: 550px;
    position: relative;

    .switch-toggle-container {
      padding: 6px;
      margin-top: 0px;
      margin-bottom: 10px;
      margin-left: auto;
      margin-right: auto;

      label {
        font-family: $font-stack;
        color: $gray-8;
        font-weight: 500;
        text-transform: uppercase;
        font-size: 14px;
      }

      .switch-button {
        vertical-align: top;
        margin-left: 10px;
        margin-right: 10px;

        &.on {
          background-color: $red-primary;
        }
      }
    }

    h2 {
      font-family: $font-stack;
      font-weight: 600;
      font-size: 20px;
      color: $gray-8;
      border-bottom: 1px solid $gray-2;
      text-align: left;
      margin: 0px;
      position: relative;
      padding: 10px 20px 8px 20px;
      letter-spacing: 0px;
      text-transform: none;
    }

    p {
      padding: 20px;
      font-family: $font-stack;
      color: $gray-8;
    }

    .col-sm-3 {
      padding-left: 0px;
    }

    form {
      padding: 20px;
      font-family: $font-stack;

      input {
        border-radius: 0px;
        box-shadow: none;
      }


      button {
        border-radius: 0px;
        position: absolute;
        background-color: $blue-primary;
        color: $white;
        bottom: 20px;

        &:disabled {
          color: $gray-4;
          background-color: $blue-primary;
        }
      }

      .invite {
        position: relative;
        bottom: 0;
      }
      
      .info {
        font-size: 11px;
        color: $gray-6;
        margin-top: 4px;
      }
    }
  }
}

@media (max-width: 991px) {
  .settings {
    .vertical-nav {
      height: auto;
      border-right: none;
    }

    .content {
      height: auto;

      form {
        button {
          position: relative;
          bottom: 0px;
        }
      }
    }

    h2 {
      display: none;
    }
  }
}

@media (max-width: 991px) and (min-width: 768px) {
  .settings {
    .settings-panel {
      width: 95%;
    }
  }
}

@media (max-width: 767px) {
  .settings {
    .settings-panel {
      width: 100%;
    }

    form {
      .form-group {
        margin-left: 0px;
        margin-right: 0px;
      }
    }

    h1 {
      text-align: center;
    }
  }
}
