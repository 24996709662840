@import "variables";
.marketplace-panel {
  margin-top: 0px;
  padding: 15px;

  h3 {
    margin-top: 0px;
    font-family: $font-stack;
    text-transform: uppercase;
    font-weight: 700;
    width: 100%;
    font-size: 22px;

    .glyphicon-shopping-cart {
      margin-right: 15px;
      vertical-align: -2px;
    }
  }

  .empty-state {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 25px;
    margin-bottom: 25px;
    font-family: $font-stack;
    color: $gray-5;
    font-weight: 600;
    font-size: 18px;
    display: block;
    font-style: italic;
    width: 100%;
  }


  .song-options {
    margin-top: 15px;

    .option-heading {
      width: 100%;
      border-bottom: 1px solid $gray-2;
      margin-bottom: 25px;
      padding-left: 10px;
      display: block;

      .option-logo {
        display: inline-block;
        width: 50px;
        margin-right: 10px;
      }

      .option-title {
        display: inline-block;
        font-family: $font-stack;
        font-style: italic;
        font-weight: 600;
        padding-bottom: 5px;
        margin-top: 0px;
      }
    }
    
    .beatport-release {
      display: inline-block;
      font-family: $font-stack;
      width: 25%;

      margin-bottom: 10px;
      padding-right: 10px;
      padding-left: 10px;
      vertical-align: top;

      a {
        color: $gray-7;
      }

      img {
        width: 100%;
      }

      h5 {
        width: 100%;
      }
    }
  }
}

@media (max-width: 767px) {
  .marketplace-panel {
    margin-left: -15px;
    margin-right: -15px;

    .song-options {
      .beatport-release {
        padding-left: 5px;
        padding-right: 5px;
        width: 33.33333333333333333333333333%;

        h5 {
          font-size: 12px;
        }
      }
    }
  }
}