@import "variables";
@import 'variables';

html {
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  padding: 0;
  background-color: $gray-8;
  font-family: 'Avenir Next', Helvetica, sans-serif;
  height: 100%;
  min-width: 1440px;
  width: 100%;
}

#root {
  height: 100%;
  min-width: 1440px;
  width: 100%;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

.container-fluid {
  max-width: 1440px;
}

.no-scroll {
  height: 100%;
  overflow: hidden;
  width: 100%;
  position: fixed;
  overscroll-behavior-y: contain;
}