@import "variables";
.player-hide {
  visibility: hidden !important;
}

.m6k-player-wrapper {
  position: fixed;
  z-index: 20;
  top: 0px;
  height: 100%; 

  width: 368px;
  background-color: #222;
  box-shadow: 0px 1px 2px 1px rgba(0, 0, 0, 0.095);

  .player {
    // margin-top: 80px;
    height: 207px;
    width: 368px;
    background-color: black;
    vertical-align: top;
  }

  .m6k-player {
    position: relative;
    width: 100%;
    text-align: center;
    height: 100%;

    a {
      text-decoration: none;
    }

    @import 'controls';
    @import 'dj';
    @import 'marketplace'; 

    .video-title-wrapper {
      display: block;
    }

    .video-title {
      display: block;
      font-size: 13px;
      color: $white;
      text-align: center;

      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      padding: 15px;
      margin: 0;
      font-family: $font-stack;
      font-weight: 400;
      line-height: 18px;

      -moz-user-select: none;
      -khtml-user-select: none;
      -webkit-user-select: none;
      -ms-user-select: none;
      user-select: none;

      &:hover {
        cursor: pointer;
        text-decoration: underline;
      }
    }
  }
}
