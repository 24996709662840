@import "variables";
.following-panel {
  .m6k-panel {
    margin-top: 0px;
  }

  h2 {
    font-family: $font-stack;
    font-size: 26px;
    font-weight: 600;
    margin-top: 0px;
    color: $gray-8;
    text-transform: uppercase;
  }

  .row {
    margin-left: -5px;
    margin-right: -5px;
  }

  .m6k-panel {
    padding: 20px;
  }

  .follow-tile {
    padding: 20px;
  }

  .dj {
    margin-bottom: 10px;
    padding-left: 5px !important;
    padding-right: 5px !important;

    button {
      font-weight: 500;
      border-radius: 0px;
      border: 0px;
      width: 100%;

      transition: all 0.18s ease;

      .hover-text {
        display: none;
      }

      .glyphicon-headphones {
        margin-right: 7px;
        font-size: 13px;
        font-weight: 400;
      }
    } 

    .follow {
      background-color: $white;
      color: $gray-9;

      &:hover {
        background-color: #2980b9;
        color: $white;
      }
    }

    .following {
      background-color: $gray-9;
      color: $white;

      &:hover {
        background-color: $gray-8;
        color: $gray-4;

        .button-text {
          display: none;
        }

        .hover-text {
          display: inline-block;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .following-panel {
    margin-left: -15px;
    margin-right: -15px;
  }
}