@import "variables";
.home-lists {
  .m6k-panel {
    margin-top: 0px;
  }
}

.home-lists-section {
  margin-bottom: 40px;
  
  h3 {
    padding-left: 10px;
    padding-right: 10px;
    margin-bottom: 5px;
    text-transform: uppercase;
  }

  p {
    padding-left: 10px;
    padding-right: 10px;
    margin-top: 5px;
    margin-bottom: 5px; 
  }

  .setlist {
    max-height: 300px;
    overflow-y: scroll;
  }
}

.list-gallery {
  overflow: hidden;
  overflow-x: scroll;
  white-space: nowrap;

  .gallery-item {
    display: inline-block;
    padding: 15px;
    color: $gray-8;

    a {
      color: $gray-7;
    }

    h6 {
      margin-bottom: 0px;
    }

    .youtube-cover {
      width: 200px;
      height: 112.500px;
    }

    img {
      width: 200px;
    }
  }
}