.app-navbar {
  border: none;
  border-radius: 0px;
  background-color: #222;
  margin-bottom: 0px;

  .navbar-brand,
  .navbar-nav li a {
    line-height: 55px;
    height: 55px;
    padding-top: 0;

    text-transform: uppercase;
    font-family: $logo; 
    font-style: normal;
    color: $white;
  }

  .navbar-brand {
    font-size: 30px;
    font-weight: 700;
    margin-left: 0px !important;

    &:visited, &:hover {
      color: $white;
    }
  }

  .active>a {
    background-color: #222 !important;
    color: $white !important;

    &::after {
      content: "";
      width: 100%;
      height: 2px;
      background-color: $gray-1;
      display: block;
      margin-top: -24px;
    } 
  }

  .glyphicon-menu-down, .glyphicon-menu-up {
    display: inline;
    margin-left: 5px;
    font-size: 13px;
  }

  .m6k-navbar {
    margin: 0px !important;
    float: left!important;

    li {
      display: inline-block;      
    }
  }

  .dropdown {
    position: relative;
    float: right;
    width: auto;

    .profile-photo {
      width: 35px;
      margin-right: 10px;
      margin-bottom: 5px;
      border-radius: 50%;
    }

    ul {
      margin-right: 0px;
    }

    &.active>.selections {
      display: block;
    }

    .selections {
      position: absolute;
      padding: 0px;
      box-shadow: 0px 4px 2px 3px rgba(100, 100, 100, 0.1);
      border: none;
      top: 100%;
      width: 250px;
      right: 0px;
      margin-top: 1px;
      left: auto;
      overflow: visible;
      background-color: #222;
      display: none;
      z-index: 10;

      li {
        list-style-type: none;
        font-family: $font-stack; 
        text-transform: uppercase;
        font-weight: 600;
        border-left: 10px solid $gray-8;
        color: $gray-4;
        font-style: italic;

        a {
          color: $gray-4;
          padding: 16px 20px;
          width: 100%;
          display: block;
          
          &:hover {
            text-decoration: none;
          }
        }

        span, i {
          margin-right: 12px;
        }

        &:hover {
          cursor: pointer;
          background-color: #333;
          color: $white;
          border-left: 10px solid $red-primary;

          a {
            color: $white;
          }
        }
      }
    }
  }

  .navbar-nav {
    margin-right: 50px;

    li a {
      font-size: 15px;
      font-weight: 500;
      letter-spacing: 2px;
      padding-left: 20px;
      padding-right: 20px; 
      color: $gray-4;

      line-height: 60px;
      &:hover {
        color: $white; 
        transition: all ease 0.15s;
      }

      &:focus {
        color: $white;
      }
    }
  }
}

// @media (max-width: 767px) {
//   .navbar-mobile {
//     .glyphicon-menu-hamburger {
//       display: block !important;
//       height: 55px;
//       color: white;
//       float: right;
//       font-size: 28px;
//       line-height: 55px; 
//       top: 0px;
//       padding-right: 10px;

//       &:hover {
//         cursor: pointer;
//         color: #ccc;
//       }
//     }

//     .glyphicon-menu-right,
//     .glyphicon-menu-left {
//       vertical-align: -2px;
//     }

//     .dropdown-nav {
//       display: none;
//     }

//     .navbar-brand {
//       padding-left: 10px !important;
//       font-size: 34px !important;
//     }

//     .navbar-nav {
//       clear: both;
//       margin-right: 0px !important;
//       margin-bottom: 0px;
//       border-top: 1px solid #333;
//       display: block;
//     }

//     .subnav {
//       display: none;
//     }

//     .subnav-active {
//       display: block;
//     }

//     .active>a{
//       &::after {
//         content: none;
//       } 
//     }

//     .navbar-nav>li {
//       text-align: center;

//       a {
//         border-bottom: 1px solid #333;
//         width: 65%;
//         margin-left: auto;
//         margin-right: auto;
//       }

//       &:last-child a {
//         border-bottom: none;
//       }
//     } 
//   }
// }