.section {
	h3 {
		margin-top: 0px;
		margin-bottom: 10px;
		font-size: 20px;
		font-family: $font-stack;
		font-weight: 500;
		color: $gray-7;
		span {
			position: relative;
			padding-bottom: 2px;
		}

		span::after {
			position: absolute;
			left: 0;
			bottom: 0;
			width: 100%;
			height: 3px;
			content: "";
			border-bottom: 3px solid $gray-1;
		}
	}

	.subscription {
		position: relative;
		margin-top: 20px;

		.subscription-label {
			font-size: 16px;
			font-family: $font-stack;
			font-weight: 500;
			color: $gray-7;
		}

    &:hover {
      .subscription-label {
        text-decoration: underline;
      }

      input:not(checked)+.subscription-label:before {
        outline-color: $gray-3;
        background-color: $gray-3;
        border-color: $white;
      }
      input:checked+.subscription-label:before {
        background-color: $gray-8;
        border-color: $white;
        outline-color: $gray-8;
      }
    }

		input {
	    position: absolute;
	    opacity: 0;
	  }

	  input+.subscription-label:before {
	    content: "";
	    border: 1px solid;
	    box-sizing: border-box;
	    content: "";
	    display: inline-block;
	    left: 5px;
	    margin: 0 10px 0 0;
	    outline: 2px solid;
	    overflow: hidden;
	    position: absolute;
	    top: 3px;
	    cursor: pointer;
	    height: 15px;
	    width: 15px;
	  }

	  input:not(checked)+.subscription-label:before {
	    outline-color: transparent;
	    background-color: transparent;
	  }

	  input:checked+.subscription-label:before {
	    background-color: $gray-8;
	    border-color: $white;
	    outline-color: $gray-8;
	  }

	  input+.subscription-label {
	    color: $gray-8;
	    text-decoration: none;
	    line-height: 1.4;
	    cursor: pointer;
	    margin-left: 30px;
	  }
	}
}