@import "variables";
@import 'profile-image-modal';
@import 'uploading-image-modal';
@import 'profile-info';
@import 'genre-list-dropdown';
@import 'confirm-action-modal';

.user-profile {
	.profile-header {
		background-color: $white;
		position: relative;
		width: 100%;
		margin-top: 20px;
		display: flex;

		.profile-picture-border {
			padding: 15px;
			width: 195px;
			background-color: $white;
		}

		.profile-panel {
			width: 100%;
			position: relative;

			.profile-nav {
				background-color: $white;
			  border-top: 1px solid $gray-1;
			  width: 100%;
			  margin-bottom: 0px;
			  padding-left: 15px;
			  position: absolute;
			  bottom: 0;

				.profile-nav-item {
					list-style-type: none;
					display: inline-block;
					margin-right: 15px;

					a {
						padding: 10px;
						display: block;
						border-bottom: 2px solid $white;

						h4 {
							font-family: $font-stack;
							text-transform: uppercase;
							letter-spacing: 1px;
							font-weight: 600;
							font-size: 13px;
							display: inline-block;
							color: $gray-8;
						}

						h5 {
							font-family: $font-stack;
							text-transform: uppercase;
							letter-spacing: 0.5px;
							font-weight: 600;
							font-size: 14px;
							display: inline-block;
							margin-left: 10px;
							color: $gray-5;
						}

						&:hover {
							cursor: pointer;
							border-bottom: 2px solid $red-primary;
							transition:border-bottom-color 0.4s ease;
						}

						&.active {
							border-bottom: 2px solid $red-primary;
						}
					}
				}
			}
		}

		.profile-img-small {
			width: 100%;

			&:hover {
				cursor: pointer;
			}
		}

		.profile-info-short {
			padding: 15px;
		}

		.name {
			font-family: $font-stack;
			color: $gray-8;
			font-weight: 700;
			margin-bottom: 0px;
		}

		.profile-menu-options {
			float: right;	

			.dropdown {
				width: inherit;

				.dropdown-menu {
		      border-radius: 0px;
		      font-family: $font-stack;
		      margin: 5px 0px;
				}
			}

			#more-options {
				border-radius: 0px;
			}


		}

		.location {
			font-family: $font-stack;
			color: $gray-7;
			font-weight: 600;
			text-transform: uppercase;
			margin-bottom: 10px;
		}
	}

	.private-profile {
		font-family: $font-stack;
		text-align: center;
		padding: 25px;
		margin-top: 0px;

		h3 {
			margin-top: 0px;
			font-weight: 700;
			color: $gray-8;
		}

		h5 {
			font-weight: 500;
			font-size: 16px;
			color: $gray-7;
		}
	}

}

@media (max-width: 768px) {
	.user-profile {
		.private-profile {
			margin-left: -15px;
			margin-right: -15px;
		}

		.profile-nav {
				background-color: $white;
			  border-bottom: 1px solid $gray-1;
			  width: auto;
			  margin-bottom: 0px;
			  position: relative;
			  padding: 0px;
			  margin-left: -15px;
			  margin-right: -15px;


				.profile-nav-item {
					list-style-type: none;
					display: inline-block;
					margin-right: 10px;

					a {
						padding: 8px;
						display: block;
						border-bottom: 2px solid $white;

						h4 {
							font-family: $font-stack;
							text-transform: uppercase;
							letter-spacing: 0px;
							font-weight: 600;
							font-size: 12px;
							display: inline-block;
							color: $gray-8;
							-webkit-touch-callout: none; /* iOS Safari */
						    -webkit-user-select: none; /* Safari */
						     -khtml-user-select: none; /* Konqueror HTML */
						       -moz-user-select: none; /* Firefox */
						        -ms-user-select: none; /* Internet Explorer/Edge */
						            user-select: none; /* Non-prefixed version, currently
						                                  supported by Chrome and Opera */
						}

						h5 {
							display: none;
						}

						&:hover {
							cursor: pointer;
							border-bottom: 2px solid $red-primary;
							transition:border-bottom-color 0.4s ease;
						}

						&.active {
							border-bottom: 2px solid $red-primary;
						}
					}
				}
			}

		.profile-header {
			margin-left: -15px;
			margin-right: -15px;
			width: auto;
			margin-top: 0px;

			.profile-panel .profile-nav {
				display: none;
			}

			.profile-info-short {
				padding-left: 10px;
			}

			.name {
				font-size: 20px;
				margin-top: 10px;
			}

			.location {
				font-size: 12px;
				margin-top: 5px;
			}

			.profile-menu-options {
				float: none;
			}
		}
	}
}
