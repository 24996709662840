.ReactModal__Overlay {
  z-index: 1000;
}

.template-modal {
  width: 900px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 50px;

  .modal-header {
    background-color: $gray-9; 
    color: $white;
    padding-bottom: 0px;

    .modal-nav {
      list-style-type: none;
      padding-left: 0px;
      margin-bottom: 0px;

      li {
        display: inline-block;
        font-size: 20px;
        border-bottom: 3px solid $gray-9;
        margin-bottom: 0px;
        padding-bottom: 12px;
        margin-right: 15px;

        &:hover {
          cursor: pointer;
        }

        &.active {
          border-bottom-color: $red-primary;
        }
      }
    }

    h3 {
      margin: 0px;
    }

    .glyphicon-remove {
      font-size: 20px;
      float: right;
      line-height: 26.4px;

      &:hover {
        cursor: pointer;
      }
    }
  }

  .modal-body {
    .tab-body {

      .fixed-area {
        max-height: 600px;
        overflow: scroll;
      }

      .list-group {
        .list-group-item {
          display: flex;
          width: 100%;
          align-items: center;
          flex-wrap: wrap;
        }
      }

      .flex-break {
        flex-basis: 100%;
        height: 0;
      }

      p {
        font-size: 20px;
      }

      .loading {
        margin-top: 25px;
        margin-bottom: 25px;
        margin-left: auto;
        margin-right: auto;
        width: 60px;
      }
    }
  }
}