@import "variables";
.banner-notification {
  margin-top: -60px !important;
  margin-bottom: 60px;

  padding: 20px;
  width: 100%;
  text-align: center; 

  &.success {
  	background-color: $green-primary;

  	h3 {
  		color: $gray-8;
  	}
  }

  &.notice {
  	background-color: $yellow-primary;

  	h3 {
  		color: $gray-8;
  	}
  }

  &.error {
    background-color: $red-primary;

    h3 {
      color: $white;
    }
  }

  h3 {
    color: #333;
    font-family: $font-stack;
    font-size: 20px; 
    font-weight: 500;
  }
}
