.m6k-player-controls {
	padding: 15px;
	position: relative;
	text-align: center;
  vertical-align: top;
  width: 100%;

	.player-controls-bar {
		width: 100%;
		margin-bottom: 20px;
	}

	.volume-control {
		display: inline-block;
		position: relative;
		vertical-align: middle;

		.volume-popover {
			position: absolute;
			width: 35px;
			height: 170px;
			bottom: 45px;
			left: 0;
			right: 0;
			margin-left: auto;
			margin-right: auto;
			background-color: #222;
			z-index: 100;
			padding: 10px;
			overflow: visible;

			.volume-slider {
				position: relative;
				overflow: hidden;

				background-color: $gray-7;
				border-radius: 1.5px;
				box-shadow: inset 0 0.5em 0.5em rgba(0,0,0,0.1);
				transition: all 350ms ease-in-out;

				margin-left: auto;
				margin-right: auto;
				left: 0px;
				right: 0px;
				top: 0px;
				width: 5px;
				height: 100%;

				.volume-slider-fill {
					position: absolute;	
					display: block;
					left: 0;
					top: 0;
					height: 100%;
					width: 100%;
					transition: all 50ms cubic-bezier(0.445, 0.05, 0.55, 0.95);
					transform-origin: 0 bottom;
				}

				.volume-slider-level {
					background-color: $red-primary;
					z-index: 105;
				}
			}

			&:hover {
				cursor: pointer;
			}
		}
		
		.volume-icon {
			font-size: 18px;
			color: $white;
			padding: 20px;
			margin-left: 20px;
			margin-right: 20px;

			&:hover {
				cursor: pointer;
			}
		}
	}

	.favorite {
		display: inline-block;
		position: relative;
		vertical-align: -4px;

		font-size: 16px;
		color: $white;
		padding: 20px;
		margin-right: 20px;

		&:hover {
			cursor: pointer;
		}
	}

	.marketplace {
		display: inline-block;
		position: relative;
		margin-right: 35px;
		vertical-align: -4px;

		.glyphicon {
			color: $white;
			font-size: 16px;
			padding: 20px;

			&:hover {
				cursor: pointer;
			}
		}
	}

	.controls {
		display: inline-block;
		margin-top: 10px;

		.glyphicon {
			color: #999;
			font-size: 18px;
			margin-right: 35px;

			&:hover {
				color: $white;
			}

			&:last-child {
				margin-right: 0px;
			}
		}

		.glyphicon-pause {
			color: $white;
		}

		.glyphicon-volume-up {
			font-size: 23px;
			vertical-align: -3px;
		}

		.glyphicon-repeat, .glyphicon-random {
			font-size: 14px;
		}

		.active {
			color: $red-primary;
		}

		.player-control {
			&:hover {
				cursor: pointer;
			}
		}
	}

	.player-progress {
		position: relative;
		margin-top: 5px;
		width: 100%;

		h5 {
			font-size: 12px;
			color: $white;
			font-family: $font-stack;
			font-weight: 400;
		}

		#start {
			float: left;
		}

		#end {
			float: right;
		}

		.player-progress-bar-wrapper {
			display: inline-block;
			vertical-align: middle;
			width: 100%;

			padding-top: 20px;
			padding-bottom: 5px;
			margin-top: -15px;

			&:hover {
				cursor: pointer;
				
				.progress-bar-hover {
					display: block;
				}
			}
		}

		.player-progress-bar {
			position: relative;
			overflow: hidden;

			background-color: #444;
			border-radius: 1.5px;
			box-shadow: inset 0 0.5em 0.5em rgba(0,0,0,0.1);
			transition: all 350ms ease-in-out;

			left: 0px;
			right: 0px;
			top: 0px;
			height: 4px;

			.progress-bar-fill {
				text-align: left;
				position: absolute;	
				display: block;
				left: 0;
				top: 0;
				height: 100%;
				width: 100%;
				transition: all 75ms cubic-bezier(0.445, 0.05, 0.55, 0.95);
				transform-origin: 0 0;
			}

			.progress-bar-played {
				background-color: $red-primary;
				z-index: 4;
			}

			.progress-bar-hover {
				opacity: 0.4;
				z-index: 3;
				background-color: $gray-3;
				display: none;
				transform: scaleX(0);
				transition: none; 
			}

			.progress-bar-buffered {
				background-color: $gray-7;
				z-index: 2;
			}

		}
	}
}
