@import "variables";
.notice {
  padding: 10px 20px;
  margin-top: 0px;
  display: inline-block;
  text-align: center;
  width: 100%;

  &.yellow {
    background-color: $yellow-primary;
    color: $gray-7;
  }

  &.red {
    background-color: $red-primary;
    color: $gray-8;
  }

  &.green {
    background-color: $green-primary;
    color: $gray-8;
  }

  &.white {
    background-color: $white;
    color: $gray-7;
  }

  .message {
    font-family: $font-stack;
    font-weight: 500;
    display: inline-block;
  }

  .icon {
    display: inline-block;
    margin-left: 15px;
    margin-top: 5px;

    .remove {
      font-family: $logo;
      font-weight: 300;
      font-size: 20px;
    }

    &:hover {
      cursor: pointer;
    }
  }
}