@import "variables";
.confirm {
  background-color: $gray-8;
  height: 100%;
  padding-top: 60px;

  h1 {
    font-family: $logo;
    font-weight: 700;
    font-size: 48px;
    text-transform: uppercase;
    text-align: center;
    margin-top: 40px;
    padding: 0px;
    color: $white;
  }

  h3 {
		font-family: $font-stack;
		font-weight: 500;
    font-size: 18px;
    text-transform: uppercase;
    text-align: center;
    letter-spacing: 2px;
    padding-top: 0px;
    color: $white;
  }

  .loading {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 50px;
    padding-bottom: 20px;
    width: 65px;
  }
}