.setlist-sortable {
  .setlist-item  {
    display: flex;
    background-color: $white;
    border-bottom: 1px solid $gray-2;
    border-left: 3px solid $white;

    &:hover {
      cursor: move;
      background-color: $gray-1;
      border-left: 3px solid $gray-2;
    }

    .img-wrapper {
      width: 200px;
      height: 112.5px;
      position: relative;

      img  {
        width: 100%;
      }

      .duration-overlay {
        position: absolute;
        z-index: 1;
        bottom: 0;
        right: 0;

        h6 {
          background-color: rgba(0, 0, 0, 0.6);
          color: $white;
          font-family: $font-stack;
          padding: 2px;
          margin: 4px;
          font-weight: 400;
          font-size: 10px;
          cursor: pointer;
        }
      }
    }

    .song-details {
      padding-left: 15px;
      text-align: left;
      font-weight: 500;
    }

    .icon-display {
      align-self: center;
      border-radius: 50%;
      background-color: $white;
      width: 66px;
      height: 66px;
      font-size: 20px;
      padding: 22px;
      color: $gray-8;
      border-radius: 50%;
      border: 1px solid $gray-6;
      margin-right: 45px;
      box-shadow: inset 1px 1px 1px rgba(50,50,50,0.2);
      z-index: 10;

      &:focused {
        border: 0px;
        outline: none !important;
      }

      &.active {
        background-color: $gray-1;

        .glyphicon {
          color: $red-primary;
        }
      }

      .glyphicon {
        font-size: 20px;
      }
    }

    .btn-tools {
      align-self: center;
      margin-right: 20px;

      .set-cover-photo {
        width: 130px;
      }

      .active-cover-photo {
        background-color: $gray-9;
        color: $white;
      }

      .btn {
        border-radius: 0px;
      }

      .remove-item  {
        margin-left: 15px;
        background-color: $red-primary;
      }
    }
  }
}

.dragging-track-helper.setlist-item {
  border-left: 3px solid $red-primary;
  
  .img-wrapper {
    width: 200px;
    height: 112.5px;
    position: relative;

    img  {
      width: 100%;
    }

    .duration-overlay {
      position: absolute;
      z-index: 1;
      bottom: 0;
      right: 0;

      h6 {
        background-color: rgba(0, 0, 0, 0.6);
        color: $white;
        font-family: $font-stack;
        padding: 2px;
        margin: 4px;
        font-weight: 400;
        font-size: 10px;
        cursor: pointer;
      }
    }
  }

  .song-details  {
    padding-left: 15px;
    font-weight: 500;
  }

  .icon-display {
    align-self: center;
    border-radius: 50%;
    background-color: $white;
    width: 66px;
    height: 66px;
    font-size: 20px;
    padding: 22px;
    color: $gray-8;
    border-radius: 50%;
    border: 1px solid $gray-6;
    margin-right: 45px;
    box-shadow: inset 1px 1px 1px rgba(50,50,50,0.2);

    &.active {
      background-color: $gray-1;

      .glyphicon {
        color: $red-primary;
      }
    }

    .glyphicon {
      font-size: 20px;
    }
  }

  .btn-tools {
    align-self: center;
    margin-right: 20px;

    .set-cover-photo {
      width: 130px;
    }

    .active-cover-photo {
      background-color: $gray-9;
      color: $white;
    }

    .btn {
      border-radius: 0px;
    }

    .remove-item  {
      margin-left: 15px;
      background-color: $red-primary;
    }
  }
}

.compact-setlist-item {
  padding: 10px;
  background-color: $white;
  border-bottom: 1px solid $gray-2;
  border-left: 2px solid $white;

  display: flex;

  &:hover {
    background-color: $gray-1;
    border-left: 2px solid $gray-2;
  }

  .list-index {
    font-weight: 900;
    color: $gray-9;
    padding-right: 10px;
    font-size: 22px;
    width: 44px;
  }

  .m6k-thumbnail {
    width: 70px;
  }

  .title {
  }

  .icon-display {
    margin-left: auto;
    background-color: $white;

    .glyphicon {
      font-size: 18px;
    }
  }

  .tools {
    margin-left: 50px;
  }

  .set-cover-photo {
    border-radius: 0px;
    margin-right: 15px;
    text-transform: uppercase;
  }

  .remove-item {
    border-radius: 0px;

    font-family: $logo;
    font-weight: 700;
    background-color: $red-primary;
  }
}

.dragging-track-helper {
  border-top: 1px solid $gray-4;
  border-bottom: 1px solid $gray-4;
  background-color: $gray-2 !important;
  list-style-type: none;
  border-left: 2px solid $red-primary !important;

  pointer-events: auto !important;
  cursor: grabbing !important;

  box-shadow: 1px 1px 1px rgba(0,0,0,0.1);
}