.m6k-popover-wrapper {
  position: relative;
}

.m6k-popover {
  position: absolute;
  background-color: $white;
  top: 0px;
  z-index: 1000;
  box-shadow: 0px 1px 2px 1px rgba(0, 0, 0, 0.155);

  &:before, &:after {
    content: ' ';
    height: 0;
    position: absolute;
    width: 0;
    border: 8px solid transparent; /* arrow size */
  }

  &:before {
    border-right-color: $gray-9;  /* arrow color */

    /* positioning */
    position: absolute;
    top: 3px;
    left: -16px;
    z-index: 2;
  }

  .m6k-content {
    width: 100%;
  }

  .title {
    width: 100%;
    background-color: $gray-9;
    color: $white;
  }
}