@import "variables";
.filter-panel {
  padding: 15px 15px 30px;

  .filter {
    position: relative;
    margin-bottom: 15px;

    .glyphicon-search {
      padding: 0px;
      font-size: 15px;
      position: absolute;          
      top: 13px;
      left: 7px;
    }

    input[type=text] {
      width: 100%;
      height: 40px;
      text-indent: 25px;
      border: 1px solid #ccc;

      &:focus {
        box-shadow: none; 
        outline: 2px solid $blue-primary;
      }
    }
  }

  .filter-results {
    max-height: 250px;
    overflow-y: scroll; 
    overflow-x: hidden; 

    .filter-list {
      display: inline-block;
      vertical-align: top;
      list-style-type: none;
      padding: 0px;
      width: 50%;
      margin-top: 2px;

      .filter-option {
        margin-top: 14px;
        position: relative;
        list-style-type: none;

        h3 {
          font-size: 14px;
          margin: 0px;
        }
      }
    }
  }
}