.marketplace {
	position: relative;
}

.marketplace-popover {
	background-color: #222;
	position: absolute;
	top: -80px;
	left: 0px;
	z-index: 100;
	padding: 20px;
	padding-bottom: 10px;
	box-shadow: 0.5px 0px 1px rgba(10,10,10,0.8);
	border: 5px solid $gray-8;

	.releases {
		background-color: #222;
		display: block;
		max-height: 450px;
		overflow: scroll;
	}

	h5 {
		font-size: 12px;
		color: #fff;
		font-family: $font-stack;
		font-weight: 400;
		text-overflow: ellipsis; 
		overflow: hidden; 
		width: 125px;
		margin-top: 7px;
		margin-bottom: 0px;
		line-height: 18px;
	}

	a:hover {
		text-decoration: underline;
		color: $white;
	}

	img {
		text-align: left;
	}

	.release-row {
		width: 100%;
		display: table-row;
		text-align: center;
	}

	.release-cell {
		display: table-cell;
		padding-left: 10px;
		padding-right: 10px;
		padding-bottom: 10px;
		text-align: left;

		&:first-child {
			padding-left: 0px;
		}

		&:last-child {
			padding-right: 0px;
		}
	}

	h3 {
		font-size: 14px;
		color: #fff;
		font-family: $font-stack;
		letter-spacing: 1.4px;
		text-transform: uppercase;
		margin: 0px;
		margin-bottom: 15px;
		padding: 0px;
		text-align: left;
		width: 150px;

		span {
			display: inline-block;
		}
	}

	.glyphicon-shopping-cart {
		padding: 0px !important;
		margin: 0px;
		margin-right: 15px;
		display: inline-block;
		vertical-align: -1px;
	}
}