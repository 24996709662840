.profile-img-modal-overlay {
	position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;

  text-align: center;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.9);	

  z-index: 100;
}

.profile-img-modal {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
  width: 100%;
	max-width: 400px;
	max-height: 400px;
	margin: auto;
  outline: 0px;

	.profile-img-large {
		max-width: 400px;
    width: 100%;
	}
}

.profile-image-upload {
	position: relative;
	display: inline-block;

	.file-uploader {
		position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    padding-left: 100%;
    overflow: hidden;
    background-color: transparent;
    outline: none;
    -webkit-appearance: none;
	}
}

.profile-edit-img-modal {
	max-width: 750px;
  width: 100%;
	margin-left: auto;
	margin-right: auto;
	margin-top: 70px;

  @media (max-width: 320px) {
    margin-top: 40px;
  }

	background-color: $white;

	.modal-header {
		background-color: $gray-9;
		border-bottom: 0px;
		position: relative;
		text-align: left;

		h3 {
			text-transform: uppercase;
			font-size: 20px;
			margin: 0px;
			font-family: 'Avenir Next';
			font-weight: 400;
			letter-spacing: 1.2px;
			color: $white;
			float: left;

      @media (max-width: 320px) {
        font-size: 14px;
        letter-spacing: 0.5px;
      }
		}

		.remove{
			float: right;
      font-size: 20px;
      margin: 0px;
      font-weight: 500;
      margin-right: 10px;
      color: $white;
      font-family: 'Gill Sans';

      @media (max-width: 320px) {
        font-size: 14px;
      }

      &:hover {
        cursor: pointer;
      }
    }
	}

	.modal-body {
		padding: 0px;
		border-bottom: 0px;
		
		canvas { 
			cursor: move; 
		}

    .rotate-toolbar {
      width: 100%;
      padding-left: 10px;
      padding-right: 10px;

      .rotate-left {
        -moz-transform: scale(-1, 1);
        -webkit-transform: scale(-1, 1);
        -o-transform: scale(-1, 1);
        -ms-transform: scale(-1, 1);
        transform: scale(-1, 1);
      }

      .fa-redo {
        font-size: 18px;
        padding: 7px;
        color: $gray-7;
      }
    }

		.image-scaler {
			padding: 10px;
			width: 100%;

			.glyphicon-picture {
				color: $gray-5;
				display: inline-block;
				vertical-align: middle;

				&.dim {
					color: $gray-2;
				}

				&.small {
					font-size: 13px;
				}

				&.large {
					font-size: 22px;
				}
			}

			.slider {
				margin: 0px 25px;
				vertical-align: middle;
				width: 400px;
				display: inline-block;

        @media (max-width: 480px) {
          width: 250px;
        }

        @media (max-width: 320px) {
          width: 200px
        }
			}
		}
	}

	.modal-footer {
		.btn {
			border-radius: 0px;
			font-family: 'Avenir Next';
			font-weight: 500;
			letter-spacing: 1.0px;
			text-transform: uppercase;

      @media (max-width: 480px) {
        letter-spacing: 0px;
        font-size: 12px;
      }

      @media (max-width: 320px) {
        letter-spacing: 0px;
        font-size: 10px;
      }
		}

		.btn-delete {
			float: left;

			&:hover {
				background-color: $gray-1;
			}
		}

		.btn-change {
			margin-right: 10px;
      @media (max-width: 320px) {
        margin-right: 5px;
      }

			&:hover {
				label {
					background-color: $gray-3;
					border: 1px solid $gray-6;
				}
			}
		}

		.btn-default {
			background-color: $gray-2;
			color: $gray-8;
			margin-right: 0px;
		}

		.btn-primary {
			background-color: $blue-primary;
		}
	}
}