.ReactModal__Body--open {
  overflow: hidden;
}

.full-modal-overlay {
  position: fixed;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  background-color: $gray-1;
  z-index: 50;
}
