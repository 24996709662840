.song-results-table {
  width: 100%;
  table-layout: fixed;
}

.song-result-row {
  width: auto;
  border-bottom: 1px solid $gray-2;

  .m6k-thumb {
    padding: 15px; 
    width: 200px;
    white-space: nowrap;

    img {
      width: 185px;
    }
  }

  .song-info {
    vertical-align: top;
    word-wrap: break-word;
    white-space: nowrap;
    padding: 15px 15px 15px 15px;
    width: 500px;


    a {
      color: $gray-7;
    }

    .title {
      font-family: $font-stack;
      font-weight: 500;
      font-size: 15px;
      margin-top: 0px;
      overflow: hidden;


      &:hover {
        cursor: pointer;
        text-decoration: underline;
      }
    }

    .song-detail {
      font-family: $font-stack;
      font-size: 12px;
      color: $gray-7;
    }

    .genres {
      .genre-label {
        background-color: $red-primary;
        color: $white;
        padding: 3px 6px;

        margin-right: 5px;
        margin-top: 0px;

        font-size: 9px;
        font-family: $font-stack;
        font-weight: 500; 
        text-transform: uppercase;
        line-height: 2;
      }
    }
  }

  .song-control {
    width: 100%;
    vertical-align: middle;
    padding: 15px;

    .favorite {
      white-space: nowrap;
      width: 50px;
      vertical-align: middle;
      margin-right: 35px;

      span {
        font-size: 18px;
      }

      &:hover {
        cursor: pointer;
      }
    }

    .duration {
      font-size: 15px;
      color: $gray-7;
      font-family: $font-stack;
      display: inline-block;
      margin-right: 30px;
      margin-top: 0px;
      width: 35px;
    }

    .icon-display {
      display: inline-block;

      .glyphicon {
        font-size: 18px;
        color: $gray-7;
        padding: 14px;
      }

      .glyphicon-pause {
        font-size: 18px;
        color: $gray-7;
        border-radius: 50%;
        padding: 14px;
        border: 1px solid $gray-8;

        &:hover {
          cursor: pointer;
        }
      }

      .glyphicon-play { 
        font-size: 18px;
        color: $gray-7;
        border-radius: 50%;
        padding: 14px;
        border: 1px solid $gray-6;

        &:hover {
          cursor: pointer;
          color: $red-primary;
          border-color: $gray-8;
        }
      }
    }
  }

  &.active {
    .glyphicon {
      color: $red-primary;
    }
  }

  &.select {
    background-color: $gray-1;
  }
}

@media (max-width: 767px) {
  .results-container {
    margin-top: 0px;
  }

  .song-result-row {
    .m6k-thumb {
      padding: 15px 10px; 
      width: 115px;

      img {
        width: 100%;
      }
    }

    .song-info {
      width: 100%;
      padding: 15px 10px;
      padding-left: 0px;

      .title {
        font-size: 13px;
        margin-bottom: 2px;
        text-overflow: ellipsis;
      }

      .genres {
        width: 140px;

        .genre-label {
          padding: 3px 4px;
          font-size: 8.5px;
          line-height: 1.5;
          overflow-wrap: break-word;
          margin-bottom: 4px;
        }
      }

      .song-detail {
        margin-top: 5px;
        margin-bottom: 0px;
      }

      .favorite {
        white-space: nowrap;
        width: 30px;
        display: inline-block;
        margin-top: 0px;
        margin-right: 5px;
        line-height: 10px;

        span {
          font-size: 18px;
          top: 3px;
        }

        &:hover {
          cursor: pointer;
        }
      }

      .duration {
        font-size: 13px;
        color: $gray-7;
        font-family: $font-stack;
        display: inline-block;
        margin-bottom: 0px;
        margin-right: 5px;
        margin-top: 5px;
        width: 35px;
        line-height: 20px;
      }

      .icon-display {
        display: inline-block;
      }
    }
  }
}