.block-user-modal, .report-user-modal {
  position: absolute;
  top: 300px;
  left: 0px;
  right: 0px;
  max-width: 400px;
  width: 100%;
  margin: auto;

  .modal-header {
    background-color: $gray-9;
    border-bottom: 0px;
    position: relative;
    text-align: left;

    h3 {
      text-transform: uppercase;
      font-size: 20px;
      margin: 0px;
      font-family: 'Avenir Next';
      font-weight: 400;
      letter-spacing: 1.2px;
      color: $white;
      float: left;
    }

    .remove{
      float: right;
      font-size: 20px;
      margin: 0px;
      font-weight: 500;
      margin-right: 10px;
      color: $white;
      font-family: 'Gill Sans';

      &:hover {
        cursor: pointer;
      }
    }
  }

  .modal-body {
    background-color: $white;
    text-align: left;

    .m6k-radio {
      font-size: 14px;
      text-transform: none;
    }
  }

  .modal-footer {
    border-top: 1px solid $gray-2;
    background-color: $white;
    margin: 0px;
    padding: 10px;

    .btn {
      border-radius: 0px;
      font-family: $font-stack; 
      font-weight: 500;
      letter-spacing: 1.0px;
      text-transform: uppercase;
    }

    .btn-default {
      background-color: $gray-2;
      color: $gray-8;
      margin-right: 10px;
    }

    .btn-primary {
      background-color: $blue-primary;
    }

  }
}