@import "variables";
@import 'search-modal';
@import 'search-dropdown';

.m6k-search {
  margin-top: 12px;
  margin-bottom: 12px;
  margin-right: 0px;
  margin-left: 0;
  padding: 0px;

  position: relative;
  width: auto!important;
  float: left;

  border-color: rgba(255,255,255,0.0)!important;
  border: none!important;
  box-shadow: none!important;

  .form-group {
    display: inline-block!important;
    vertical-align: middle!important;
  }

  input {
    min-width: 300px;
    padding: 5px 12px;
    height: 32px;

    border-top-left-radius: 7px;
    border-bottom-left-radius: 7px;

    border-top-right-radius: 0px; 
    border-bottom-right-radius: 0px;

    border: 0px;

    font-size: 16px;
    font-family: $font-stack;
    font-weight: 400;
    vertical-align: middle;

    &:focus {
      outline: none;
    }
  }

  button {
    border: 0px;
    padding: 5px 25px;
    line-height: 22px;
    height: 32px;
    background-color: $gray-1;
    vertical-align: middle;

    border-top-right-radius: 7px;
    border-bottom-right-radius: 7px;

    &:hover {
      background-color: $gray-2;
    }

    &:focus {
      outline: none;
    }
  }
}

// @media (max-width: 767px) {
//   .m6k-search {
//     margin: 0px;
//     padding: 7px;

//     .form-group {
//       width: 100%;

//       input {
//         width: 85%;
//         padding: 8px 15px;

//       }

//       button {
//         width: 15%;
//         padding: 8px 15px;
//       }
//     }

//   }

// }
