@import "variables";
.m6k-checkbox {

  .option-label {
    font-family: $font-stack;
    color: $gray-7;
  }

  &:hover {
    .option-label {
      text-decoration: underline;
    }

    input:not(checked)+.option-label:before {
      outline-color: $gray-3;
      background-color: $gray-3;
      border-color: $white;
    }
    
    input:checked+.option-label:before {
      background-color: $gray-8;
      border-color: $white;
      outline-color: $gray-8;
    }
  }

  input {
    position: absolute;
    opacity: 0;
  }

  input+.option-label:before {
    content: "";
    border: 1px solid;
    box-sizing: border-box;
    content: "";
    display: inline-block;
    left: 5px;
    margin: 0 10px 0 0;
    outline: 2px solid;
    overflow: hidden;
    position: absolute;
    top: 1px;
    cursor: pointer;
    height: 18px;
    width: 18px;
  }

  input:not(checked)+.option-label:before {
    outline-color: transparent;
    background-color: transparent;
  }

  input:checked+.option-label:before {
    background-color: $gray-8;
    border-color: $white;
    outline-color: $gray-8;
  }

  input+.option-label {
    color: $gray-8;
    text-decoration: none;
    line-height: 1.4;
    cursor: pointer;
    margin-left: 30px;
  }

  &:first-child {
    margin-top: 0px;
  } 
}