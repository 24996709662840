.profile-info-detailed {
	margin: 0px;
	margin-bottom: 20px;
  margin-right: -10px;

	.centered {
		text-align: center;
	}

	section {
		border-bottom: 1px solid $gray-1;
		padding: 15px;
	}

	.glyphicon-pencil {
		float: right;
		color: $gray-3;

		&:hover {
			color: $gray-7;
			cursor: pointer;
		}
	}

	.glyphicon-add {
		color: $gray-5;
	}

	.edit-title {
		float: left;
	}

	.profile-url {
		padding: 6px;
		font-family: $font-stack;
	}

	p {
		clear: both;
		padding: 6px;
		margin: 0px;
		font-family: $font-stack;
	}

	textarea {
		width: 100%;
		margin-top: 2px;
		padding: 6px 12px;
		resize: vertical;
	}

	.switch-toggle-container {
		padding: 6px;
		margin-top: 10px;
		margin-bottom: 10px;
		margin-left: auto;
		margin-right: auto;

		label {
			font-family: $font-stack;
			color: $gray-8;
			font-weight: 500;
			text-transform: uppercase;
			font-size: 14px;
		}

		.switch-button {
			vertical-align: top;
			margin-left: 10px;
			margin-right: 10px;

			&.on {
				background-color: $red-primary;
			}
		}
	}

	.info-text {
		font-family: $font-stack;
		color: $gray-6;
		font-size: 13px;
		margin-bottom: 0px;
		margin-left: 6px;
		padding: 0px;
	}

	.btn {
		border-radius: 0px;
		font-family: $font-stack;
		text-transform: uppercase;
		font-size: 13px;
		text-align: center;
	}

	.btn-primary {
		background-color: $blue-primary;
		margin-left: 5px;
	}

	h4 {
		font-family: $font-stack;
		color: $gray-8;
		font-weight: 700;
		text-transform: uppercase;
		margin: 0px;
		padding-left: 5px;
	}

	input {
		border-radius: 0px;
		margin-top: 10px;
	}

	.genres {
		clear: both;
    overflow-wrap: normal;
  }

  .profile-loading {
  	height: 60px;
  	width: 60px;
  	margin: auto;
  	position: relative;
  	vertical-align: middle;
  }
}

.genre-label {
  display: inline-block;
  line-height: 26px;
  margin: 5px;
  font-weight: 500;
  text-transform: uppercase;
  border-radius: 0px;
  background-color: $red-primary;
  color: $white;
  font-family: $font-stack; 
  font-size: 10px;

  .name {
	  padding: 8px;
  }

  &.label-edit {
  	.name {
  		cursor: move;
  	}
  }

  .remove {
  	font-family: 'Gill Sans';
  	padding: 8px;
  	padding-left: 2px;
  	font-size: 9px;
  	font-weight: 700;

  	&:hover {
  		cursor: pointer;
  	}
  }

  &:first-child {
  	margin-left: 0px;
  }
} 

.genre-dragger {
	&:hover {
		cursor: move;
	}
	
	-webkit-box-shadow: 0px 0px 1px 1px rgba(0,0,0,0.43);
	-moz-box-shadow: 0px 0px 1px 1px rgba(0,0,0,0.43);
	box-shadow: 0px 0px 1px 1px rgba(0,0,0,0.43);
	z-index: 1000;
}

@media (max-width: 992px) {
  .profile-info-detailed {
    margin-right: 0px;
  }
}

@media (max-width: 768px) {
  .profile-info-detailed {
    margin-left: -15px;
    margin-right: -15px;
    margin-bottom: 10px;
  }
}