.m6k-tabs-container {
  background-color: $white;
}

.m6k-nav-list {
  display: block;
  margin: 0px;
  padding: 0px;

  li {
    list-style-type: none;
    display: inline-block;
  }

  .active a {
    border-bottom: 2px solid $red-primary;
    font-weight: 600;
  }

  li a {
    display: block;
    padding: 15px 20px;
    font-family: $font-stack;
    text-transform: uppercase;
    color: $gray-8;
    font-weight: 500;
    border-bottom: 2px solid $white;
    letter-spacing: 1px;

    &:hover {
      border-bottom: 2px solid $red-primary;
      text-decoration: none;
    }

    &:focus {
      text-decoration: none;
    }
  }
}