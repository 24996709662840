@import "variables";
// @import 'dj_wall_popover';
@import 'dropdown-menu';
@import 'sortable-setlist';

.setlist-container {
  margin-right: 0px;
  margin-left: 0px;

  .heading {
    background-color: #222;
    width: 100%;
    margin-left: 0px;
    margin-right: 0px;
  }

  .glyphicon-refresh, .glyphicon-pencil, .glyphicon-ok, .glyphicon-list,  .glyphicon-th-list {
    float: right;
    margin-right: 10px;
    font-size: 18px; 
    padding: 15px 0px;
    font-weight: 300;
    color: $white !important;

    &:hover {
      cursor: pointer;
    }
  }

  .glyphicon-list, .glyphicon-th-list {
    float: right;
    margin-right: 10px;
    font-size: 18px; 
    padding: 15px 0px;
    font-weight: 300;
    color: $gray-4;

    &.active {
      color: $white;
      transition-property: color;
      transition-duration: 2s;
      transition-timing-function: ease-out;
    }

    &:hover {
      cursor: pointer;
    }
  }

  h3 {
    width: 100%;
    font-family: $font-stack;
    font-weight: 600;
    font-size: 18px;
    text-transform: uppercase;
    color: $white;
    padding: 15px 0px;
    text-align: left;
    margin: 0px;

    span {
      text-transform: none;
    }
  }
}

.setlist {
  padding: 0px;
  margin: 0px;
  list-style-type: none;
}

.setlist-item {
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 15px;
  border-bottom: 1px solid $gray-2;
  border-left: 3px solid $white;
  transition: all 0.22s;

  display: flex;
  position: relative;

  &:hover {
    background-color: $gray-1;
    border-left: 3px solid $gray-2;
    cursor: pointer;
  }

  &.select {
    // background-color: $gray-1;
    // border-left: 3px solid $gray-5;
  }

  &.active {
    background-color: $gray-2;
    border-left: 3px solid $red-primary;
  }

  &:last-child {
    border-bottom: 0px;
  }
}

.m6k-thumbnail {
  position: relative;
  display: inline-block;
  width: 200px;
  margin-right: 15px;

  .yt-thumbnail {
    width: 100%;
  }

  .duration-overlay {
    position: absolute;
    z-index: 1;
    bottom: 0;
    right: 0;

    h6 {
      background-color: rgba(0, 0, 0, 0.6);
      color: $white;
      font-family: $font-stack;
      padding: 2px;
      margin: 4px;
      font-weight: 400;
      font-size: 10px;
      cursor: pointer;
    }
  }
}

.setlist-item.active .song-details .song-description .title .description a {
  color: $red-primary;
}

.setlist-item:hover .song-details .player-control .glyphicon-play {
  border: 1px solid $gray-6;
  color: $gray-8;
  box-shadow: inset 1px 1px 1px rgba(50,50,50,0.2);
}

.setlist-item.active .song-details .player-control .glyphicon-volume-up {
  color: $red-primary;
}

.song-details {
  display: inline-block;
  vertical-align: top;
  padding-top: 7px;
  flex: 1;
  position: relative;
  width: 100%;

  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  .song-description {
    // width: 440px;
    flex: 1;

    .title {
      width: 100%;
      margin-bottom: 5px;

      a {
        color: $gray-8;
        font-family: $font-stack;
        font-weight: 500;
      }

      .icon-display {
        display: inline-block;
        margin-right: 10px;
        vertical-align: middle;
        margin-top: 3px;

        .glyphicon {
          font-size: 20px;
          color: $gray-8;
          &:hover {
            cursor: pointer;
          }
        }
      }
    }

    .genres {
      .genre-label {
        background-color: $red-primary;
        color: $white;
        padding: 3px 6px;

        margin-right: 5px;
        margin-top: 0px;

        font-size: 9px;
        font-family: $font-stack;
        font-weight: 500; 
        text-transform: uppercase;
        line-height: 2;
      }
    }

    .song-bpm-key {
      font-family: $font-stack;
      color: $gray-7;
    }
  }

  .player-control {
    align-self: center;
    width: 66px;
    height: 66px;
    margin-right: 45px;

    .icon-display {
      .glyphicon {
        font-size: 20px;
        padding: 22px;
        color: $gray-8;
        border-radius: 50%;
        border: 1px solid rgba(0,0,0,0);
      }

      .glyphicon-play, .glyphicon-pause {
        border: 1px solid $gray-6;
        background-color: $gray-1;
        box-shadow: inset 1px 1px 1px rgba(50,50,50,0.2);
          
        transition: all 0.22s;
        transition-timing-function: ease-out;

        &:hover {
          box-shadow: inset 1px 1px 1px rgba(50,50,50,0.4);
          background-color: $white;
          color: $gray-9;
        }
      }
    }
  }

  .playlist-dropdown {
    align-self: center;
    padding: 35px;

    &:hover {
      cursor: pointer;
    }

    .glyphicon {
      font-size: 20px;
    }
  }

  .favorite {
    align-self: center;
    margin-right: 35px;
    margin-left: 35px;

    &:hover {
      cursor: pointer;
    }

    .glyphicon {
      font-size: 20px;
    }
  }
}

.no-select {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}

.setlist-empty {
  width: 100%;
  padding: 20px;
  text-align: center;
  list-style-type: none;
  border-top: 1px solid $gray-2;

  .loading {
    margin-right: auto;
    margin-left: auto;
    width: 45px !important;
  }

  .logo {
    font-family: $logo;
    color: $gray-8;
    font-weight: 800;
    text-align: center;
    font-size: 28px;
    margin: 0px;
    padding: 0px;
  }
}

.skeleton-listitem {
  width: 100%;
  border-bottom: 1px solid $gray-2;
  padding: 15px;
  display: flex;

  &:last-child {
    border-bottom: 0px;
  }

  .skeleton-video-thumbnail {
    display: inline-block;
    width: 200px;
    height: 113px;
    background-color: $gray-1;
    margin-right: 15px;
    vertical-align: middle;
  }

  .skeleton-description {
    width: 100%;
    display: inline-block;
    vertical-align: middle;
    flex: 1;
    margin-top: 15px;

    .r1 {
      width: 550px;
      height: 14px;
      background-color: $gray-1;
      margin-bottom: 15px;
    }

    .r2 {
      width: 350px;
      height: 12px;
      background-color: $gray-1;
    }
  }
}

@media(max-width: 767px) {
  .setlist-container {
    margin-top: 0px;
    margin-left: 0px;
    margin-right: 0px;

    .heading {
      display: none;
    }
  }

  .setlist-item.active .song-details .player-control .icon-display .glyphicon {
    color: $red-primary;
  }

  .setlist-item {
    padding: 10px;

    &.active {
      border-left: 0px;
    }

    &:hover {
      border-left: 0px;
    }

    .m6k-thumbnail {
      width: 115px;
      display: table-cell;
      padding: 10px;

      .duration-overlay {
        display: none;
      }
    }
    
    .song-details {
      display: table-cell;
      padding: 10px 10px 10px 0px;
      width: fit-content;

      // overflow: hidden;
      // word-break: break-word;
      // white-space: nowrap;

      .song-description {
        .song-bpm-key {
          display: none;
        }
      }

      .player-control {
        display: inline-block;
        margin: 0px;
        margin-right: 5px;
        vertical-align: middle;
        width: inherit;
        height: inherit;

        .icon-display {
          .glyphicon {
            padding: 10px;
            border: 0px;
            border-radius: 0px;
            background-color: initial;
            box-shadow:none;
          }
        }
      }

      .playlist-dropdown {
        display: inline-block;
        vertical-align: middle;
        margin: 0px;
        padding: 0px;

        .glyphicon {
          padding: 10px;
        }
      }

      .favorite {
        display: inline-block;
        vertical-align: middle;
        margin: 0px;

        .glyphicon {
          padding: 10px;
        }
      }
    }
  }

}
