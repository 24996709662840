@import "variables";
.playlists {
  margin-top: 0px;

  @media (max-width: 767px) {
    margin-left: -15px;
    margin-right: -15px;
  }

  .heading {
    background-color: $gray-9;
    color: $white;

    h3 {
      margin: 0px;
      margin-top: 15px;
      padding: 6px 0px;
      text-transform: uppercase;
      font-size: 18px;
      font-weight: 600;
    }

    .create-playlist {
      background-color: $white;
      color: $gray-9;
      font-weight: 500;
      border-radius: 0px;
      float: right;
      margin-top: 15px;
      margin-bottom: 15px;

      &:hover {
        color: $red-primary;
        background-color: $gray-1;
      }
    }
  }
}

.playlist-summary {
  margin-top: 15px;

  a {
    color: $gray-9;
  }

  img {
    width: 100%;
  }

  h3 {
    color: $gray-9;
  }

  .status-tag {
    color: $gray-9;
  }
}

.create-playlist-modal {
  width: 600px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 120px;

  .modal-header {
    background-color: $gray-9; 
    color: $white;

    h3 {
      margin: 0px;
    }

    .glyphicon-remove {
      font-size: 20px;
      float: right;
      line-height: 26.4px;

      &:hover {
        cursor: pointer;
      }
    }
  }

  .modal-body {
    form {
      label {
        text-transform: uppercase;
      }

      p {
        color: $gray-6;
        margin-bottom: 15px;
      }

      textarea { resize: vertical; }

      input, textarea {
        border-radius: 0px;
      }

      .btn-group {
        float: right;

        .btn-primary {
          background-color: $blue-primary;

          &:hover {
            background-color: $blue-primary-darker;
          }
        }
      }

      .switch-toggle-container {
        padding: 0px;
        margin-bottom: 10px;
        margin-left: auto;
        margin-right: auto;

        .switch-button {
          vertical-align: top;
          margin: 0px 10px;
        }

        .switch-button.on {
          background-color: $red-primary;
        }

        label {
          font-weight: 500;
        }
      }
    }
  }
}