@import "variables";
.img-tile {
  padding: 0px !important;

  .overlay-wrapper {
    position: relative;
    
    img {
      width: 100%;
      background-color: $gray-3;
    }
    
    .overlay {
      position: absolute;
      text-align: left;
      left: 0px;
      top: 0px;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.6);
      opacity: 0.1;
      
      transition: opacity 0.25s ease;
      h3 {
        font-weight: 600;
        margin-top: 10px !important;
        font-size: 14px;
        text-transform: uppercase;
        color: $white;

        position: absolute;
        bottom: 18px;
        left: 18px;
        margin: 0px;
      }
    }

    &:hover {
      .overlay {
        cursor: pointer;
        opacity: 1.0;
      }
    }
  }
}