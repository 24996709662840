@import "variables";
.friends {
  margin-top: 0px;
  padding: 20px;

  h3 {
    margin-top: 0px;
    padding: 15px;
    font-family: $font-stack;
    font-weight: 600;
    font-size: 22px;
  }

  .profile-preview {
    img {
      width: 100%;
    }
  }

  .btn-group {
    margin-bottom: 10px;

    .user-option-btn {
      border-radius: 0px;
      font-family: $font-stack;
      background-color: $gray-7;
      color: $white;

      &.active {
        background-color: $gray-9;
      }
    }
  }

  .row {
    margin-left: -5px;
    margin-right: -5px;
  }

  .img-tile {
    padding-left: 5px !important;
    padding-right: 5px !important;

    margin-bottom: 10px;
  }

  .friend-button-wrapper {
    width: 100%;
    
    .default-action-btn {
      width: 100%;
      border: 0px;
    }

    .dropdown {
      width: 100%;

      #receive-friend-request-button {
        width: 100%;
        border: 0px;
      }

      #sent-friend-request-button {
        width: 100%;
        border: 0px;
      }

      #friend-button {
        width: 100%;
        border: 0px;
      }
    }
  }

}

@media (max-width: 768px) {
  .friends {
    margin-left: -15px;
    margin-right: -15px;

    .user-option-btn {
      font-size: 12px;
    }
  }
}