.m6k-dropdown-menu {
  position: absolute;
  top: 70px;
  right: 15px;
  z-index: 10;
  background-color: $white;
  list-style-type: none;
  padding-left: 0px;

  li {
    padding: 15px;
    text-transform: uppercase;

    &:hover {
      background-color: $gray-8;
      color: $white;
    }
  }
}