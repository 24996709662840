@import "variables";
.login-modal-overlay {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;

  text-align: center;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4); 

  z-index: 9999999;
}

.login-modal {
  position: absolute;
  top: 200px;
  left: 0px;
  right: 0px;
  max-width: 450px;
  width: 100%;
  margin: auto;
  background-color: $white;

  .modal-header {
    background-color: $gray-9; 
    color: $white;
    padding: 10px;

    h5 {
      margin-left: 10px;
      text-align: left;
      font-size: 20px;
      font-family: 'Avenir Next';
      font-weight: 400;
      color: $white;
    }

    .cancel-btn {
      position: absolute;
      right: 15px;
      top: 15px;
      font-size: 20px;
      margin: 0px;
      font-weight: 500;
      margin-right: 10px;
      color: $gray-9;
      font-family: 'Gill Sans';

      &:hover {
        cursor: pointer;
      }
    }
  }

  .login-form {
    width: 100%;
    padding: 20px;
    margin-left: auto;
    margin-right: auto;

    .login, .reset {
      width: 100%;
      background-color: $gray-9;
      font-family: $font-stack;
      font-weight: 600;
      font-size: 16px;
      letter-spacing: 1.0px;
      color: white;
      text-transform: uppercase;
      border: none;
      border-radius: 0px;
      margin-top: 15px;
    }

    .loading {
      display: block;
      margin-left: auto;
      margin-right: auto;

      margin-top: 20px;
      width: 65px;
    }

    .error-message {
      font-family: $font-stack;
      font-weight: 400;
      font-size: 14px;
      padding: 4px;
      padding-left: 12px;
      background-color: $red-primary;
      color: $white;
      border: 1px solid $red-primary;
      display: inline-block;
      vertical-align: 5px;
      width: 100%;
      position: relative;
    }

    input {
      border-radius: 0px;
      font-family: $font-stack;
      border-top: none;
      border-left: none;
      border-right: none;
      box-shadow: none;
      border-bottom: 2px solid $gray-6;
      letter-spacing: 0.25px;
      font-weight: 500;
      color: $gray-7;

      &:focus {
        outline: 0 none;
        box-shadow: none;
      }

      &::placeholder {
        text-transform: uppercase;
        font-weight: 500;
      }

      &.form-error {
        border-bottom-color: $red-primary;
      }
    }
  }

  .register-link {
    text-align: center;
    display: block;

    font-family: $font-stack;
    color: $gray-5;
    margin-top: 13px;
    margin-bottom: 0px;
    text-transform: uppercase;
  }

  .password-reset {
    display: block;
    margin-top: 10px;
    text-align: left !important;
    font-family: $font-stack;
    color: $gray-7;
    font-style: italic;
  }

  .modal-footer {
    background-color: $white;
    border-top: 0px;
    margin: 0px;
    padding: 10px;

    .btn {
      border-radius: 0px;
      font-family: $font-stack; 
      font-weight: 500;
      letter-spacing: 1.0px;
      text-transform: uppercase;
    }

    .btn-default {
      background-color: $gray-2;
      color: $gray-8;
      margin-right: 10px;
    }

    .btn-primary {
      background-color: $blue-primary;
    }
  }
}