@import "variables";
.transitions {
  .m6k-panel {
    margin-top: 0px;
    margin-left: 0px;
  }  
}

@media (max-width: 767px) {
  .transitions {
    margin-left: -15px;
    margin-right: -15px;

    .heading {
      display: none;
    }
  }
}