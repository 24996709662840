.m6k-panel {
  background-color: $white;
  box-shadow: 0px 1px 2px 1px rgba(0, 0, 0, 0.095);
  margin-bottom: 20px;
  margin-top: 20px;

  @media (max-width: 767px) {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}
