@import "variables";
.not-found {
  .not-found-panel {
    padding: 20px;
    text-align: center;

    img {
      width: 240px;
      margin-left: auto;
      margin-right: auto;
      display: block;
    }

    h3 {
      font-family: $font-stack;
      font-weight: 700;
      color: $gray-8;
    }

    h4 {
      font-family: $font-stack;
      font-weight: 500;
      color: $gray-7;
    }
  }
}