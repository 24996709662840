@import "variables";
@import 'dj-nav';
@import 'dj-profile-header';
@import 'dj-info';

.dj-profile {
  .results {
    margin-left: 0px;
  }
  
  .setlist-container {
    margin-top: 0px;
    margin-left: 0px;
    margin-right: 0px;
  }
}

@media (max-width: 767px) {
  .dj-profile {
    .setlist-container {
      margin-left: -15px;
      margin-right: -15px;

      .heading {
        display: none;
      }
    }
  }
}