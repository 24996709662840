@import "variables";
.legal-nav {
  background-color: $white !important;
  margin-bottom: 20px;
  width: 100%;

  .search-nav-list {
    display: block;
    margin: 0px;
    padding: 0px; 

    li {
      list-style-type: none;
      display: inline-block;
    }

    .active a {
      border-bottom: 2px solid $red-primary;
      font-weight: 600;
      background-color: $white;
    }

    li a {
      display: block;
      padding: 15px 20px;
      font-family: $font-stack;
      text-transform: uppercase;
      color: $gray-8;
      font-weight: 500;
      border-bottom: 2px solid $white;
      letter-spacing: 1px;

      &:hover {
        border-bottom: 2px solid $red-primary;
        text-decoration: none;
      }

      &:focus {
        text-decoration: none;
      }
    }
  }
}

.legal {
  padding: 25px;
  background-color: $white;

  h1 {
    margin-top: 0px;
  }

  h2, h1 {
    font-family: $font-stack;
    font-size: 22px;
    color: $gray-8;
    font-weight: 600;
    margin-bottom: 5px;
  }

  h3 {
    font-family: $font-stack;
    font-size: 16px;
    color: $gray-8;
    font-weight: 600;
    font-style: italic;
    margin-bottom: 5px;
  }

  h5 {
    font-family: $font-stack;
    margin: 0px;
    font-size: 14px; 
    color: $gray-6;
  }

  b {
    font-weight: 600;
    color: $gray-8;
    font-style: normal;
  }

  p, ol, ul {
    overflow-wrap: break-word;
    font-family: $font-stack;
    color: $gray-7;
    font-size: 16px;
    font-weight: 300;
    // text-align: justify;
  }

  a {
    overflow-wrap: break-word;
  }
}

@media (max-width: 767px) {
  .legal-nav {
    margin-bottom: 0px;

    .search-nav-list {
      li a {
        letter-spacing: 0.5px;
        font-size: 11px;
        padding: 12px 15px;
      }
    }
  }

  .legal {
    padding: 17px;
    margin-left: -5px;
    margin-right: -5px;

    h2, h1 {
      font-size: 18px;
    }

    h3 {
      font-size: 14px;
    }

    p, ol, ul {
      font-size: 14px;
      font-weight: 400;
    }

    h5 {
      font-size: 13px;
    }
  }
}

@media screen and (max-width: 320px) {
  .legal-nav {
    margin-bottom: 0px;

    .search-nav-list {

      li a {
        letter-spacing: 0.2px;
        font-size: 10px;
        padding: 10px 12px;
      }

    }
  }
}