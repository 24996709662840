.blocked-users {
  .blocked-users-wrapper {
    height: 400px;
    overflow: scroll;
  }

  table {
    tr {
      .img-prev {
        width: 80px;
        padding: 10px;
        padding-left: 35px;
      } 

      .title {
        width: 250px;
        font-family: $font-stack;
        color: $gray-7;
        font-weight: 500;
        vertical-align: middle;
      }

      .unblock-btn {
        vertical-align: middle;
      }

      .empty {
        padding: 20px;
        font-family: $font-stack;
        font-weight: 500;
        color: $gray-7;
        font-size: 16px;
      }

      img {
        width: 50px;
      }

      button {
        border-radius: 0px;
        font-family: $font-stack;
      }

      .btn-primary {
        background-color: $blue-primary;
      }
      
      .btn-danger {
        background-color: $red-primary;
      }

    }
  }
}