@import "variables";
@import 'search-mobile';
@import 'search-songs';

.search-container {
  .search-tabs-container {
    background-color: $white;
  }

  .search-nav-list {
    display: block;
    margin: 0px;
    padding: 0px;

    li {
      list-style-type: none;
      display: inline-block;
    }

    .active a {
      border-bottom: 2px solid $red-primary;
      font-weight: 600;
    }

    li a {
      display: block;
      padding: 15px 20px;
      font-family: $font-stack;
      text-transform: uppercase;
      color: $gray-8;
      font-weight: 500;
      border-bottom: 2px solid $white;
      letter-spacing: 1px;

      &:hover {
        border-bottom: 2px solid $red-primary;
        text-decoration: none;
      }

      &:focus {
        text-decoration: none;
      }
    }
  }
}

.results-container {
  width: 100%;

  .results-list {
    width: 100%;
  }

  .skeleton-row {
    width: 100%;
    padding: 15px;
    border-bottom: 1px solid $gray-2;

    .video-square {
      width: 200px;
      padding: 15px;

      div {
        width: 185px;
        height: 104px;
        background-color: $gray-1;
      }
    }

    .square {
      div {
        width: 92px;
        height: 92px;
        background-color: $gray-1;
        margin: 15px 5px 15px 15px;
      }
    }

    .video-long-rectangle {
      word-wrap: break-word;
      white-space: nowrap;
      padding: 15px 15px 15px 15px;
      width: 100%;

      .r1 {
        width: 500px;
        height: 20px;
        background-color: $gray-1;
      }

      .r2 {
        margin-top: 12px;
        width: 250px;
        height: 12px;
        background-color: $gray-1;
      }
    }

    .long-rectangle {
      padding: 10px;

      .r1 {
        width: 500px;
        height: 20px;
        background-color: $gray-1;
      }

      .r2 {
        margin-top: 12px;
        width: 250px;
        height: 12px;
        background-color: $gray-1;
      }
    } 
  }

  td {
    white-space:nowrap;

    @media (max-width: 480px) {
      white-space: pre-wrap;
    }
  }

  .header {
    background-color: $gray-9;
    padding: 0px;

    h3 {
      font-family: $font-stack;
      color: $white;
      text-transform: uppercase; 
      padding: 15px 0px;
      font-weight: 600;
      font-size: 18px;
      margin: 0px;
    }
  }

  .result-row {
    border-bottom: 1px solid $gray-2;

    &:hover {
      background-color: $gray-1;
    }

    .genres {
      .genre-label {
        background-color: $red-primary;
        color: $white;
        padding: 3px 6px;

        margin-right: 5px;
        margin-top: 0px;

        font-size: 9px;
        font-family: $font-stack;
        font-weight: 500; 
        text-transform: uppercase;
        line-height: 2;
      }
    }

    .preview-image {
      img {
        width: 120px;
        padding: 10px 15px;
      }
    }

    .action-row {
      width: 100%;

      .friend-button-wrapper, .follow-button {
        float: right;
        margin-right: 20px;
      }
    }

    .info {
      padding: 10px;
      vertical-align: middle;

      h3 {
        font-size: 18px;
        color: $gray-7;
        font-family: $font-stack;
        margin-bottom: 0px;
      }

      h5 {
        font-size: 14px;
        color: $gray-6;
        font-family: $font-stack;
        margin-top: 6px;
        margin-bottom: 7px;
      }
    }
  }
}

@media (max-width: 992px) { 
  .results-container {

    .skeleton-row {
      .square {
        div {
          width: 92px;
          height: 92px;
          background-color: $gray-1;
          margin: 15px 5px 15px 15px;
        }
      }

      .video-long-rectangle {
        .r1 {
          width: 81%;
        }

        .r2 {
          width: 62%;
        }
      }

      .long-rectangle {
        padding: 10px;
        width: 100%;

        .r1 {
          width: 85%;
          height: 20px;
          background-color: $gray-1;
        }
      }
    }

    .result-row {

      .preview-image {
        vertical-align: top;
        padding: 0px;

        img {
          width: 100px;
          padding: 10px;
        }
      }

      .info {
        padding: 10px 0px;

        h3 {
          font-size: 15px;
          margin: 0px;
        }

        h5 {
          font-size: 13px;
        }
      }

      .genres {
        width: 140px;

        .genre-label {
          padding: 3px 4px;
          font-size: 8.5px;
          line-height: 1.5;
          overflow-wrap: break-word;
          margin-bottom: 4px;
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .search-results .row .col-xs-12 {
    padding: 0px;
  }
}

@media (max-width: 480px) { 
  .results-container {
    .skeleton-row {
      .square {
        div {
          width: 82px;
          height: 82px;
        }
      }

      .long-rectangle {
        .r2 {
          width: 45%;
        }
      }
    }

    .result-row {

      .preview-image {
        img {
          width: 90px;
          padding: 10px;
        }
      }

      .info {
        padding: 10px 0px;

        h3 {
          font-size: 16px;
          margin: 0px;
        }

        h5 {
          font-size: 13px;
        }
      }

      .genres {
        width: 150px;

        .genre-label {
          padding: 3px 4px;
          font-size: 8.5px;
          line-height: 1.5;
          overflow-wrap: break-word;
          margin-bottom: 4px;
        }
      }
    }
  }
}

@media (max-width : 320px) {
  .results-container {
    .result-row {
      .preview-image {
        img {
          width: 90px;
          padding: 10px;
        }
      }

      .info {
        padding: 10px 0px;

        h3 {
          font-size: 15px;
          margin: 0px;
        }

        h5 {
          font-size: 12px;
        }
      }

      .genres {
        width: 120px;

        .genre-label {
          padding: 3px 4px;
          font-size: 8.5px;
          line-height: 1.5;
          overflow-wrap: break-word;
          margin-bottom: 2px;
          margin-top: 0px;
        }
      }
    }
  }
}

.setlist-empty {
  width: 100%;

  td {
    padding: 20px;
    font-family: $font-stack;
    color: $gray-7;
    font-weight: 600;
    text-align: center;
  }
  
  .loading {
    margin-right: auto;
    margin-left: auto;
    width: 45px !important;
  }

  p {
    margin: 0px;
    padding: 0px;
  }

  .logo {
    font-family: $logo;
    color: $gray-8;
    font-weight: 800;
    text-align: center;
    font-size: 28px;
    margin: 0px;
    padding: 0px;
  }
}

