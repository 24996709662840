@import "variables";
.follow-button {
  font-weight: 600;
  border-radius: 0px;
  width: 125px;
  transition: all 0.18s ease;

  .hover-text {
    display: none;
  }

  .glyphicon-headphones {
    margin-right: 7px;
    font-size: 13px;
    font-weight: 400;
  }


  &.follow {
    background-color: $white;
    color: $gray-9;

    &:hover {
      background-color: #2980b9;
      color: $white;
    }
  }

  &.following {
    background-color: $gray-9;
    color: $white;

    &:hover {
      background-color: $gray-6;
      color: $gray-1;

      .button-text {
        display: none;
      }
      .hover-text {
        display: inline-block;
      }
    }
  }
} 

@media (max-width: 992px) {
  .follow-button {
    width: 105px;
    font-size: 12px;

    .glyphicon-headphones {
      font-size: 12px;
    }
  }
}

@media (max-width: 480px) {
  .follow-button {
    width: 75px;
    padding: 7px;
    font-size: 11px;
    
    .glyphicon-headphones {
      display: none;
    }
  }
}