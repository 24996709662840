@import "variables";
@import 'playlist-info';
@import 'playlist-collaborators';

.playlist-header {
  display: flex;
  width: 100%;

  .title  {
    margin: 0px;
  }

  .title-editable  {
    border: 1px solid $white;
    display: inline-block;

    &:hover  {
      border: 1px solid $gray-2;
    }
  }

  .playlist-album-art {
    width: 390px;
    padding: 15px;

    // .empty-image {
    //   width: 272px;
    //   height: 153px; 
    //   background-color: $gray-1;
    // }

    img {
      width: 100%;
    }

  }

  .playlist-detail-panel {
    width: 100%;
    position: relative;
  }
}

.playlist-nav {
  background-color: $white;
  border-top: 1px solid $gray-1;
  width: 100%;
  margin-bottom: 0px;
  padding-left: 15px;

  position: absolute;
  bottom: 0;

  .profile-nav-item {
    list-style-type: none;
    display: inline-block;
    margin-right: 15px;

    a {
      padding: 10px;
      display: block;
      border-bottom: 2px solid $white;

      h4 {
        font-family: $font-stack;
        text-transform: uppercase;
        letter-spacing: 1px;
        font-weight: 600;
        font-size: 13px;
        display: inline-block;
        color: $gray-8;
      }

      h5 {
        font-family: $font-stack;
        text-transform: uppercase;
        letter-spacing: 0.5px;
        font-weight: 600;
        font-size: 14px;
        display: inline-block;
        margin-left: 10px;
        color: $gray-5;
      }

      &:hover {
        cursor: pointer;
        border-bottom: 2px solid $red-primary;
        transition:border-bottom-color 0.4s ease;
      }

      &.active {
        border-bottom: 2px solid $red-primary;
      }
    }
  }
}

.playlist-tracks {
  .setlist-container { 
    margin-top: 0px;

    @media (max-width: 767px) {
      margin-left: -15px;
      margin-right: -15px;
    }

    .empty {
      padding: 15px;

      h4 {
        font-style: italic;
        font-size: 18px;
      }
    }
  }
}