@import "variables";
.public-header {
  .navbar {
    border-radius: 0px;
    margin-bottom: 0px;
    background-color: $white;

    h1 {
      font-family: $logo;
      font-weight: 700;
      font-size: 48px;
      line-height: 86px;
      margin: 0px;

      a {
        text-decoration: none;
        color: $gray-9;
      }
    }

    .navbar-nav {
      .sign-in {
        font-size: 26px;
        line-height: 86px;
        padding-top: 0px;
        padding-bottom: 0px;
        margin-right: 15px;
      }

      .sign-up {
        border-radius: 2px;
        background-color: $red-primary;
        border: 0px;
        font-size: 20px;
      }
    }
  }
}