.search-modal-fs {
  padding: 0px;

  width: 100%;
  background-color: $white;
  padding-bottom: 20px;
  border-bottom: 2px solid $gray-2;
  outline: none;


  .m6k-search {
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 0px;
  }

  .search-nav-list {
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 15px;

    li {
      display: inline-block;
      border: 1px solid $gray-5;
      margin-right: 10px;
      margin-bottom: 10px;

      &.active a {
        background-color: $white;
        color: $gray-9;
      }

      a {
        padding: 10px 25px;
        display: block;
        font-family: $font-stack;
        text-decoration: none;
        color: $gray-7;
        background-color: $gray-1;
        font-size: 13px;
        font-weight: 500;
        text-transform: uppercase;
      }
    }
  }

  .close-modal {
    padding-left: 15px;
  } 

  .search-controls-top {
    padding-left: 15px;
    padding-right: 15px;
    height: 50px;
    display: table;
    width: 100%;

    .glyphicon-remove {
      padding: 0px;
      font-size: 18px;
    }

    .option {
      display: table-cell;
      vertical-align: middle;
    }

    .option-l {
      text-align: left;
      width: 20%;
    }

    .option-c {
      text-align: center;
      width: 40%;
    }

    .option-r {
      text-align: right;
      width: 20%;

      color: $red-primary;
    }

    h2 {
      margin: 0px;
      font-size: 14px;
      font-family: $font-stack;
    }
  }
}