.confirm-password-modal {
  width : 520px;
  padding : 0px;
  top : 20%;
  left : 50%;
  right : auto;
  bottom : auto;
  margin-right : -50%;
  transform : translate(-50%, -20%);
  border-radius : 0px;
  position: absolute;
  background-color: $white;
  border: 1px solid $gray-3;

  .modal-header {
    position: relative;
    padding: 0px;

    h2 {
      font-size: 20px;
      font-family: $font-stack;
      text-align: center;
      color: $gray-7;
    }

    .glyphicon-remove{
      position: absolute;
      top: 20px;
      right: 20px;

      &:hover {
        cursor: pointer;
      }
    }
  }

  .modal-body {
    input {
      border-radius: 0px;
      box-shadow: none;
    }
  }

  .modal-footer {
    button {
      border-radius: 0px;
    }
  }
}

@media (max-width: 767px) {
  .confirm-password-modal {
    width: 100%;
  }
}